import { useEffect } from "react";
import BusinessHero from "../../components/business/BusinessHero";
import BusinessForm from "../../components/business/BusinessForm";

export default function Business() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BusinessHero />

      <BusinessForm />
    </>
  );
}
