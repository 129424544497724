import { useOutletContext } from "react-router-dom";
import { useState } from "react";
import Loading from "../../../components/Loading";
import { sendRequest } from "../../../util/util";

export default function EditClientProfile() {
  const userData = useOutletContext();

  const [formData, setFormData] = useState(() => {
    return {
      data: { ...userData, newPassword: "", password: "" },
      status: {
        success: false,
        errors: {
          name: {
            error: false,
            msg: "",
          },
          cpf: {
            error: false,
            msg: "",
          },
          birthday: {
            error: false,
            msg: "",
          },
          email: {
            error: false,
            msg: "",
          },
          phone: {
            error: false,
            msg: "",
          },
          cep: {
            error: false,
            msg: "",
          },
          newPassword: {
            error: false,
            msg: "",
          },
          password: {
            error: false,
            msg: "",
          },
        },
      },
    };
  });

  const [isLoading, setIsLoading] = useState(() => false);

  return (
    <div className="pt-11">
      <article className="bg-customGray-profileGray p-3 pt-6 pb-10 flex flex-col gap-10 sm:p-6 sm:pb-10 items-center lg:p-10 lg:pb-14">
        <h1 className="text-2xl text-center font-bold text-primary sm:text-3xl lg:text-4xl">
          Editar informações
        </h1>

        <form
          onSubmit={(event) =>
            handleSubmit(event, formData, setFormData, setIsLoading)
          }
          noValidate
          className="mt-8 flex flex-col  items-center gap-6 w-full max-w-[19rem]"
        >
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="name" className="text-primary font-bold text-lg">
              Nome:
            </label>
            <input
              id="name"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.name}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="name"
            />

            {formData.status.errors.name.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.name.msg}
              </p>
            )}
          </div>
          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="cpf" className="text-primary font-bold text-lg">
              CPF:
            </label>
            <input
              id="cpf"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.cpf}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="cpf"
            />

            {formData.status.errors.cpf.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.cpf.msg}
              </p>
            )}
          </div>
          <div className="w-full flex flex-col gap-2 ">
            <label
              htmlFor="birthday"
              className="text-primary font-bold text-lg"
            >
              Nascimento:
            </label>
            <input
              id="birthday"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.birthday}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="date"
              name="birthday"
            />

            {formData.status.errors.birthday.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.birthday.msg}
              </p>
            )}
          </div>
          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="email" className="text-primary font-bold text-lg">
              Email:
            </label>
            <input
              id="email"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.email}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="email"
              name="email"
            />

            {formData.status.errors.email.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.email.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="phone" className="text-primary font-bold text-lg">
              Telefone:
            </label>
            <input
              id="phone"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.phone}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="phone"
            />

            {formData.status.errors.phone.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.phone.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="cep" className="text-primary font-bold text-lg">
              CEP:
            </label>
            <input
              id="cep"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.cep}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="cep"
            />

            {formData.status.errors.cep.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.cep.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label
              htmlFor="new-password"
              className="text-primary font-bold text-lg"
            >
              Nova Senha:
            </label>
            <input
              id="new-password"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.newPassword}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="password"
              name="newPassword"
              placeholder="********"
            />

            {formData.status.errors.newPassword.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.newPassword.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label
              htmlFor="password"
              className="text-primary font-bold text-lg"
            >
              Confirmar alterações:
            </label>
            <input
              id="password"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.password}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="password"
              name="password"
              placeholder="Sua senha"
            />

            {formData.status.errors.password.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.password.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-4 mt-4">
            <button
              className="p-2 hover:border-primary border text-base lg:text-lg cursor-pointer w-full font-bold text-primary bg-customBlue-whiteBlue rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="submit"
            >
              Atualizar
            </button>

            {formData.status.success && (
              <p className="text-green-500 text-center font-medium text-base lg:text-lg">
                Seus dados foram atualizados com sucesso!
              </p>
            )}
          </div>
        </form>
      </article>
      {isLoading && <Loading />}
    </div>
  );
}

function handleSubmit(event, formData, setFormData, setIsLoading) {
  event.preventDefault();

  const newPassword = formData.data.newPassword;
  const newData = { ...formData.data };

  let method = "POST";

  if (!newPassword) {
    delete newData.newPassword;
    method = "PUT";
  }

  setIsLoading(() => true);

  const requestData = {
    method,
    body: newData,
    url: "clients/profile/update",
  };

  handleRequest(requestData, setIsLoading, setFormData);
}

async function handleRequest(requestData, setIsLoading, setFormData) {
  try {
    const response = await sendRequest(requestData);

    if (!response.success) {
      const errors = response.errors;

      setFormData((prevFormData) => {
        const newFormData = { ...prevFormData };
        for (let error in prevFormData.status.errors) {
          if (errors[error]) {
            newFormData.status.errors[error].error = true;
            newFormData.status.errors[error].msg = errors[error];
          } else {
            newFormData.status.errors[error].error = false;
          }
        }

        newFormData.status.success = false;

        return newFormData;
      });

      return;
    }

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      for (let error in prevFormData.status.errors) {
        newFormData.status.errors[error].error = false;
      }

      newFormData.status.success = true;

      const newUserData = { ...newFormData.data };

      delete newUserData.password;
      delete newUserData.newPassword;

      return newFormData;
    });

    localStorage.setItem("userData", JSON.stringify(response.data));

    setTimeout(() => {
      setFormData((prevFormData) => {
        return {
          data: { ...prevFormData.data, password: "", newPassword: "" },
          status: { ...prevFormData.status, success: false },
        };
      });
    }, 3000);
  } catch (error) {
    window.alert(
      "Houve um error no servidor, por favor, tente novamente mais tarde."
    );
  } finally {
    setIsLoading(() => false);
  }
}

function handleOnChange(event, setFormData) {
  const target = event.target;

  const targetName = target.name;
  const targetValue = target.value;

  setFormData((prevFormData) => {
    return {
      ...prevFormData,
      data: { ...prevFormData.data, [targetName]: targetValue },
    };
  });
}
