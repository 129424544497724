import LoginForm from "../../components/auth/LoginForm";
import { useEffect } from "react";

export default function ClientLogin() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="p-2 pt-16 pb-10">
      <article>
        <LoginForm formFor="Clientes" />
      </article>
    </section>
  );
}
