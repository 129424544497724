import { Link } from "react-router-dom";

export default function ClientForm() {
  return (
    <section className="sm:mt-10 pb-20">
      <article className="flex flex-col gap-4 items-center p-3">
        <h2 className="text-3xl text-primary font-bold lg:text-4xl">
          Cadastro
        </h2>

        <p className="text-base text-primary font-bold text-center max-w-[40ch] lg:text-lg">
          Garanta já seu cadastro e fique por dentro de todas as atualizações,
          ajudando esse projeto a crescer.
        </p>

        <Link
          className="p-3 mt-10 pr-10 pl-10 text-nowrap bg-primary rounded-2xl text-lg text-white hover:bg-customBlue-darkBlue transition-all duration-300"
          to={"/cliente/registrar-se"}
        >
          Garantir Cadastro
        </Link>
      </article>
    </section>
  );
}
