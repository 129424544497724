import { useState, useEffect } from "react";
import Loading from "../../../components/Loading";
import { sendRequest } from "../../../util/util";
import { useOutletContext } from "react-router-dom";

export default function EditBusinessProfile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userData = useOutletContext();
  const clientRequiredInfos = JSON.parse(userData.requiredInfos);

  const [formData, setFormData] = useState(() => {
    return {
      data: {
        ...userData,
        clientRequiredData: {
          clientName: clientRequiredInfos.includes("Nome"),
          clientEmail: clientRequiredInfos.includes("Email"),
          clientCpf: clientRequiredInfos.includes("CPF"),
          clientPhone: clientRequiredInfos.includes("Telefone"),
          clientCep: clientRequiredInfos.includes("CEP"),
          clientBirthday: clientRequiredInfos.includes("Aniversário"),
        },
        newPassword: "",
        password: "",
        type: "business",
      },
      status: {
        success: false,
        errors: {
          name: {
            error: false,
            msg: "",
          },
          cnpj: {
            error: false,
            msg: "",
          },
          email: {
            error: false,
            msg: "",
          },
          phone: {
            error: false,
            msg: "",
          },
          cep: {
            error: false,
            msg: "",
          },
          password: {
            error: false,
            msg: "",
          },
          newPassword: {
            error: false,
            msg: "",
          },
          clientRequiredData: {
            error: false,
            msg: "",
          },
        },
      },
    };
  });

  const [isLoading, setIsLoading] = useState(() => false);

  return (
    <div className="pt-11">
      <article className="bg-customGray-profileGray p-3 pt-14 pb-10 flex flex-col gap-10 sm:p-6 sm:pb-10 items-center lg:p-10 lg:pb-14 lg:pt-14">
        <div className="flex flex-col gap-6">
          <h1 className="text-3xl text-center font-bold text-primary sm:text-4xl lg:text-5xl">
            Editar informações
          </h1>
        </div>

        <form
          onSubmit={(event) =>
            handleSubmit(event, formData, setFormData, setIsLoading)
          }
          noValidate
          className="mt-8 flex flex-col  items-center gap-6 w-full max-w-[19rem]"
        >
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="name" className="text-primary font-bold text-lg">
              Razão Social:
            </label>
            <input
              id="name"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.name}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="name"
              placeholder="Razão Social"
            />

            {formData.status.errors.name.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.name.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="cnpj" className="text-primary font-bold text-lg">
              CNPJ:
            </label>
            <input
              id="cnpj"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.cnpj}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="cnpj"
              placeholder="CNPJ"
            />

            {formData.status.errors.cnpj.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.cnpj.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="email" className="text-primary font-bold text-lg">
              Email:
            </label>
            <input
              id="email"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.email}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="email"
              name="email"
              placeholder="Email"
            />

            {formData.status.errors.email.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.email.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="phone" className="text-primary font-bold text-lg">
              Telefone:
            </label>
            <input
              id="phone"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.phone}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="phone"
              placeholder="Telefone"
            />

            {formData.status.errors.phone.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.phone.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="cep" className="text-primary font-bold text-lg">
              CEP:
            </label>
            <input
              id="cep"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.cep}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="cep"
              placeholder="CEP"
            />

            {formData.status.errors.cep.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.cep.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-4 ">
            <h2
              htmlFor="required-infos"
              className="text-primary font-bold text-2xl"
            >
              Dados requeridos dos seus clientes:
            </h2>

            <div className="flex gap-4 flex-wrap">
              <div className="flex items-center gap-2">
                <label
                  htmlFor="client-name"
                  className="text-primary font-bold text-base cursor-pointer"
                >
                  Nome:
                </label>

                <input
                  id="client-name"
                  onChange={(event) => handleOnChange(event, setFormData)}
                  className="cursor-pointer"
                  checked={formData.data.clientRequiredData.clientName}
                  type="checkbox"
                  name="clientName"
                  placeholder="Nome do cliente"
                />
              </div>

              <div className="flex items-center gap-2">
                <label
                  htmlFor="client-cpf"
                  className="text-primary font-bold text-base cursor-pointer"
                >
                  CPF:
                </label>

                <input
                  id="client-cpf"
                  onChange={(event) => handleOnChange(event, setFormData)}
                  className="cursor-pointer"
                  checked={formData.data.clientRequiredData.clientCpf}
                  type="checkbox"
                  name="clientCpf"
                  placeholder="CPF"
                />
              </div>

              <div className="flex items-center gap-2">
                <label
                  htmlFor="client-email"
                  className="text-primary font-bold text-base cursor-pointer"
                >
                  Email:
                </label>

                <input
                  id="client-email"
                  onChange={(event) => handleOnChange(event, setFormData)}
                  className="cursor-pointer"
                  checked={formData.data.clientRequiredData.clientEmail}
                  type="checkbox"
                  name="clientEmail"
                  placeholder="Email"
                />
              </div>

              <div className="flex items-center gap-2">
                <label
                  htmlFor="client-phone"
                  className="text-primary font-bold text-base cursor-pointer"
                >
                  Telefone:
                </label>

                <input
                  id="client-phone"
                  onChange={(event) => handleOnChange(event, setFormData)}
                  className="cursor-pointer"
                  checked={formData.data.clientRequiredData.clientPhone}
                  type="checkbox"
                  name="clientPhone"
                  placeholder="Telefone"
                />
              </div>

              <div className="flex items-center gap-2">
                <label
                  htmlFor="client-cep"
                  className="text-primary font-bold text-base cursor-pointer"
                >
                  CEP:
                </label>

                <input
                  id="client-cep"
                  onChange={(event) => handleOnChange(event, setFormData)}
                  className="cursor-pointer"
                  checked={formData.data.clientRequiredData.clientCep}
                  type="checkbox"
                  name="clientCep"
                  placeholder="CEP"
                />
              </div>

              <div className="flex items-center gap-2">
                <label
                  htmlFor="client-birthday"
                  className="text-primary font-bold text-base cursor-pointer"
                >
                  Nascimento:
                </label>

                <input
                  id="client-birthday"
                  onChange={(event) => handleOnChange(event, setFormData)}
                  className="cursor-pointer"
                  checked={formData.data.clientRequiredData.clientBirthday}
                  type="checkbox"
                  name="clientBirthday"
                  placeholder="Nascimento"
                />
              </div>
            </div>

            {formData.status.errors.clientRequiredData.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.clientRequiredData.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label
              htmlFor="new-password"
              className="text-primary font-bold text-lg"
            >
              Nova senha:
            </label>
            <input
              id="new-password"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.newPassword}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="password"
              name="newPassword"
              placeholder="Sua nova Senha"
            />

            {formData.status.errors.newPassword.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.newPassword.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label
              htmlFor="password"
              className="text-primary font-bold text-lg"
            >
              Confirmar alterações:
            </label>
            <input
              id="password"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.password}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="password"
              name="password"
              placeholder="Sua senha"
            />

            {formData.status.errors.password.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.password.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-4 mt-4">
            <button
              className="p-2 hover:border-primary border text-base lg:text-lg cursor-pointer w-full font-bold text-primary bg-customBlue-whiteBlue rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="submit"
            >
              Salvar alterações
            </button>

            {formData.status.success && (
              <p className="text-green-500 text-center font-medium text-base lg:text-lg">
                Seus dados foram salvos com sucesso!
              </p>
            )}
          </div>
        </form>
      </article>
      {isLoading && <Loading />}
    </div>
  );
}

function handleSubmit(event, formData, setFormData, setIsLoading) {
  event.preventDefault();

  let method = "POST";
  const confirmFormData = { ...formData.data };

  if (!formData.data.newPassword) {
    method = "PUT";
    delete confirmFormData.newPassword;
  }

  setIsLoading(() => true);

  const requestData = {
    method,
    body: confirmFormData,
    url: "businesses/profile/update",
  };

  handleRequest(requestData, setIsLoading, setFormData);
}

async function handleRequest(requestData, setIsLoading, setFormData) {
  try {
    const response = await sendRequest(requestData);

    if (!response.success) {
      const errors = response.errors;

      setFormData((prevFormData) => {
        const newFormData = { ...prevFormData };
        for (let error in prevFormData.status.errors) {
          if (errors[error]) {
            newFormData.status.errors[error].error = true;
            newFormData.status.errors[error].msg = errors[error];
          } else {
            newFormData.status.errors[error].error = false;
          }
        }

        newFormData.status.success = false;

        return newFormData;
      });

      return;
    }

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      for (let error in prevFormData.status.errors) {
        newFormData.status.errors[error].error = false;
      }

      newFormData.status.success = true;

      const requiredInfos = response.data.requiredInfos;

      const newRequiredInfos = requiredInfos.map((info) => {
        if (info === "name") {
          return "Nome";
        }

        if (info === "phone") {
          return "Telefone";
        }

        if (info === "email") {
          return "Email";
        }

        if (info === "cpf") {
          return "CPF";
        }

        if (info === "birthday") {
          return "Nascimento";
        }

        if (info === "cep") {
          return "CEP";
        }
      });

      response.data.requiredInfos = JSON.stringify(newRequiredInfos);

      localStorage.setItem("userData", JSON.stringify(response.data));

      return newFormData;
    });

    setTimeout(() => {
      setFormData((prevFormData) => {
        return {
          data: {
            ...prevFormData.data,
            newPassword: "",
            password: "",
            type: "business",
          },
          status: { ...prevFormData.status, success: false },
        };
      });
    }, 4000);
  } catch (error) {
    window.alert(
      "Houve um error no servidor, por favor, tente novamente mais tarde."
    );
  } finally {
    setIsLoading(() => false);
  }
}

function handleOnChange(event, setFormData) {
  const target = event.target;

  const targetName = target.name;
  const targetValue = target.value;

  const inputType = target.type;

  setFormData((prevFormData) => {
    return {
      ...prevFormData,
      data: {
        ...prevFormData.data,
        [inputType === "checkbox" ? "clientRequiredData" : targetName]:
          inputType === "checkbox"
            ? {
                ...prevFormData.data.clientRequiredData,
                [targetName]: target.checked,
              }
            : targetValue,
      },
    };
  });
}
