import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useState } from "react";

import logoWebp from "../assets/images/logo.webp";

export default function Header() {
  const [showSideBar, setShowSideBar] = useState(() => false);

  const currentLocation = useLocation().pathname;

  const sidebarAnchors = getSideBanAnchors();

  const sidebarAnchorsElements = sidebarAnchors.map((anchor) => {
    if (
      currentLocation === "/empresa" ||
      currentLocation === "/empresa-login" ||
      currentLocation === "/empresa/registrar-se"
    ) {
      if (anchor.path === "/cliente-login") {
        anchor.path = "/empresa-login";
      } else if (anchor.path === "/cliente/registrar-se") {
        anchor.path = "/empresa/registrar-se";
      }
    }

    return (
      <Anchor
        key={anchor.id}
        path={anchor.path}
        routeName={anchor.routeName}
        currentLocation={currentLocation}
      />
    );
  });

  const headerAnchors = getHeaderAnchors();
  const headerAnchorsElements = headerAnchors.map((anchor) => {
    return (
      <Anchor
        key={anchor.id}
        path={anchor.path}
        routeName={anchor.routeName}
        isHeader={anchor.isHeader}
        currentLocation={currentLocation}
      />
    );
  });

  return (
    <header className="bg-customBlue-darkBlue fixed top-0 left-0 right-0 z-50">
      <div className="h-20 flex justify-between items-center pr-4">
        <Link to={"/"}>
          <img
            className="h-20 aspect-square"
            src={logoWebp}
            onError={(e) => {
              e.target.src = logo;
            }}
          />
        </Link>

        <div className="flex items-center justify-between md:w-full md:pr-4 md:pl-6 xl:pr-8 xl:pl-14">
          <nav className="hidden md:block">
            <ul className="flex items-center gap-8">{headerAnchorsElements}</ul>
          </nav>

          <i
            onClick={() => handleToggleSideBar(setShowSideBar)}
            className={`icon-bars text-4xl cursor-pointer text-customBlue-whiteBlue hover:text-customGray-whiteGray regular-transition ${
              showSideBar && "text-customGray-whiteGray"
            }`}
          ></i>
        </div>
      </div>

      <SideBar
        showSideBar={showSideBar}
        sidebarAnchorsElements={sidebarAnchorsElements}
      />
    </header>
  );
}

function handleToggleSideBar(setShowSideBar) {
  setShowSideBar((prevShowSideBar) => !prevShowSideBar);
}

function SideBar(props) {
  return (
    <aside
      className={`absolute bg-customBlue-darkBlue transition-all duration-300 top-20 ${
        props.showSideBar ? "right-0" : "-right-[30rem]"
      } w-full max-w-64 pt-4 pb-4 flex flex-col items-center gap-16 min-h-screen`}
    >
      <article className="w-fit relative flex flex-col items-center justify-center">
        <h2 className="text-5xl text-customBlue-whiteBlue relative">Menu</h2>

        <div className="h-[4.2rem] w-[6.3rem] border-[6px] rounded-[.8rem] border-white absolute -top-[.4.3 rem]"></div>
      </article>

      <nav>
        <ul className="flex flex-col items-center gap-8">
          {props.sidebarAnchorsElements}
        </ul>
      </nav>
    </aside>
  );
}

function Anchor(props) {
  const isHeader = props.isHeader;

  let displayDesktop = true;

  if (!isHeader) {
    if (props.routeName === "Início" || props.routeName === "Comunidade") {
      displayDesktop = false;
    }
  }

  const currentLocation = props.currentLocation;
  let active = false;

  if (currentLocation === props.path) {
    active = true;
  }

  return (
    <li className={`${!displayDesktop && "md:hidden"}`}>
      <Link
        to={props.path}
        className={`text-lg text-customBlue-whiteBlue font-normal cursor-pointer regular-transition hover:text-customGray-whiteGray ${
          active && "text-customGray-whiteGray"
        }`}
      >
        {props.routeName}
      </Link>
    </li>
  );
}

function getSideBanAnchors() {
  const anchors = [
    {
      id: 1,
      path: "/",
      routeName: "Início",
    },
    {
      id: 2,
      path: "/comunidade",
      routeName: "Comunidade",
    },
    {
      id: 3,
      path: "/empresa",
      routeName: "Empresa",
    },
    {
      id: 4,
      path: "/cliente",
      routeName: "Cliente",
    },
  ];

  const isLogged = localStorage.getItem("userData");

  if (isLogged) {
    anchors.push(
      {
        id: 7,
        path:
          JSON.parse(localStorage.getItem("userData")).type === "client"
            ? "/perfil-cliente"
            : "/perfil-empresa",
        routeName: "Perfil",
      },
      {
        id: 8,
        path: "/sair",
        routeName: "Sair",
      }
    );
  } else {
    anchors.push(
      {
        id: 5,
        path: "/cliente-login",
        routeName: "Login",
      },
      {
        id: 6,
        path: "/cliente/registrar-se",
        routeName: "Cadastro",
      }
    );
  }

  return anchors;
}

function getHeaderAnchors() {
  return [
    {
      id: 1,
      path: "/",
      routeName: "Início",
      isHeader: true,
    },
    {
      id: 2,
      path: "/comunidade",
      routeName: "Comunidade",
      isHeader: true,
    },
  ];
}
