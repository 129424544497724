import { Navigate } from "react-router-dom";
import Loading from "../Loading";
import { sendRequest } from "../../util/util";
import { useState, useEffect } from "react";

export default function LotOut() {
  const [type, setType] = useState(() => localStorage.getItem("type"));
  let sendTo = "/";

  if (type === "client") {
    sendTo = "/cliente-login";
  } else if (type === "business") {
    sendTo = "/empresa-login";
  }

  const [isLoading, setIsLoading] = useState(() => true);

  useEffect(() => {
    logOut(setIsLoading);
  }, []);

  return <div>{isLoading ? <Loading /> : <Navigate to={sendTo} />}</div>;
}

async function logOut(setIsLoading) {
  try {
    const requestData = {
      method: "POST",
      url: "log-out",
    };

    const response = await sendRequest(requestData);

    if (response.success) {
      setIsLoading(false);
      return;
    }

    window.alert(
      "Houve um problema ao sair da página, tente novamente mais tarde."
    );
  } catch (error) {
    window.alert(
      "Houve um problema ao sair da página, tente novamente mais tarde."
    );
  } finally {
    localStorage.clear();
  }
}
