import PostMain from "./main/PostMain";
import { useEffect } from "react";

export default function PostHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PostMain />
    </div>
  );
}
