import heroImage from "../../assets/images/hero-image.png";
import heroImageWebp from "../../assets/images/hero-image.webp";

export default function Hero() {
  return (
    <section>
      <article className="pt-28 pb-10 flex items-center justify-center p-3">
        <h1 className="hidden">Sulkey</h1>

        <picture>
          <img
            src={heroImageWebp}
            className="w-full max-w-[28rem]"
            onError={(e) => {
              e.target.src = heroImage;
            }}
          />
        </picture>
      </article>
    </section>
  );
}
