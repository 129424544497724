import { Outlet, Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/hero-image.webp";
import { useEffect } from "react";

export default function ProfileLayout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation().pathname;

  const anchors = getAnchors().map((anchor) => {
    if (location === anchor.url) {
      anchor.active = true;
    } else {
      anchor.active = false;
    }

    return (
      <PerfilAnchor
        key={anchor.id}
        name={anchor.name}
        url={anchor.url}
        active={anchor.active}
      />
    );
  });

  const pageType = localStorage.getItem("type");

  const isClient = pageType === "client" ? true : false;
  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <section className="p-3 block max-w-5xl m-auto ">
      <div>
        <div className="flex items-center gap-6 relative pt-8 pb-8">
          <picture className="flex-shrink-0">
            <img
              src={userData.img}
              className="w-20 aspect-square rounded-full object-cover sm:w-28 lg:w-32 cursor-pointer"
            />
          </picture>

          <article className="text-primary font-bold text-sm overflow-hidden sm:text-base lg:text-lg">
            <h2 className="whitespace-nowrap overflow-hidden overflow-ellipsis capitalize">
              {userData.name}
            </h2>
            <h3>
              {isClient ? "CPF" : "CNPJ"}:{" "}
              <mark className="text-primary font-bold">
                {isClient ? userData.cpf : userData.cnpj}
              </mark>
            </h3>
          </article>

          <img
            src={logo}
            className="w-20 absolute right-0 top-2 sm:w-28 lg:w-32"
          />
        </div>

        <div className="p-2 pr-4 pl-4 border font-medium text-primary w-full bg-customGray-profileGray rounded-3xl regular-shadow">
          <nav>
            <ul className="grid grid-cols-3 gap-3 text-sm sm:text-base lg:text-lg">
              {anchors}
            </ul>
          </nav>
        </div>
      </div>

      <Outlet context={JSON.parse(localStorage.getItem("userData"))} />
    </section>
  );
}

function PerfilAnchor(props) {
  return (
    <li className="col-span-1 flex justify-center items-center relative">
      <Link
        to={props.url}
        className={`whitespace-nowrap hover:text-customBlue-darkBlue ${
          props.active && "text-customBlue-darkBlue"
        } transition duration-200 overflow-hidden overflow-ellipsis`}
      >
        {props.name}
      </Link>

      <div
        className={`absolute -bottom-14 arrow-up ${
          props.active ? "block" : "hidden"
        }`}
      ></div>
    </li>
  );
}

function getAnchors() {
  const clientAnchors = [
    {
      id: 1,
      name: "Minhas Informações",
      url: "/perfil-cliente",
      active: true,
    },
    {
      id: 2,
      name: "Meus Cadastros",
      url: "/perfil-cliente/meus-cadastros",
      active: false,
    },
    {
      id: 3,
      name: "Autorizações",
      url: "/perfil-cliente/autorizacoes",
      active: false,
    },
  ];

  const businessAnchors = [
    {
      id: 1,
      name: "Minhas Informações",
      url: "/perfil-empresa",
      active: true,
    },
    {
      id: 2,
      name: "Meus Clientes",
      url: "/perfil-empresa/meus-clientes",
      active: false,
    },
    {
      id: 3,
      name: "Requerir Informações",
      url: "/perfil-empresa/requerir-informacoes",
      active: false,
    },
  ];

  return localStorage.getItem("type") === "client"
    ? clientAnchors
    : businessAnchors;
}
