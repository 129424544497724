import Loading from "../../components/Loading";
import { useState, useEffect } from "react";
import { sendRequest } from "../../util/util";
import { useSearchParams, Navigate, Link } from "react-router-dom";

export default function ActiveUser() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const type = searchParams.get("type");

  const [isActive, setIsActive] = useState(() => false);
  const [isError, setIsError] = useState(() => false);
  const [isLoading, setIsLoading] = useState(() => true);

  useEffect(() => {
    activeUserRequest(token, setIsActive, setIsError, setIsLoading);
  }, []);

  if (!token || !type) {
    return <Navigate to="/" />;
  }

  return (
    <section>
      <article className="min-h-[30rem] flex flex-col items-center justify-center p-2">
        {isError ? (
          <h1 className="text-primary text-lg text-center font-bold sm:text-2xl lg:text-3xl">
            A conta já esta ativa ou não existe.
          </h1>
        ) : (
          <div>
            {isActive ? (
              <div className="flex flex-col gap-10 items-center">
                <h1 className="text-primary text-2xl text-center font-bold sm:text-3xl lg:text-4xl">
                  Bem-vindo a Sulkey!
                </h1>

                <Link
                  to={type === "client" ? "/cliente-login" : "/empresa-login"}
                  className="p-3 pr-10 pl-10 text-center w-[15rem] block text-base text-white bg-customBlue-darkBlue rounded-lg cursor-pointer transition-all duration-200 hover:bg-customBlue-whiteBlue"
                >
                  Entrar
                </Link>
              </div>
            ) : (
              <h1 className="text-primary text-lg text-center font-bold sm:text-2xl lg:text-3xl">
                A sua conta esta sendo ativada...
              </h1>
            )}
          </div>
        )}
        {isLoading && <Loading />}
      </article>
    </section>
  );
}

async function activeUserRequest(token, setIsActive, setIsError, setIsLoading) {
  try {
    const requestData = {
      method: "POST",
      url: "active-user",
      token: token,
    };

    const response = await sendRequest(requestData);

    if (response.success) {
      setIsError(() => false);
      setIsActive(() => true);
      return;
    }

    setIsActive(() => false);
    setIsError(() => true);
  } catch (error) {
    window.alert(
      "Houve um problema no servidor e a sua conta não pode ser ativada."
    );
  } finally {
    setIsLoading(() => false);
  }
}
