import { Link } from "react-router-dom";
import businessHero from "../../assets/images/business-hero.png";
import businessHero2 from "../../assets/images/business-hero2.png";

import businessHeroWebp from "../../assets/images/business-hero.webp";
import businessHero2Webp from "../../assets/images/business-hero2.webp";

export default function BusinessHero() {
  return (
    <section className="pt-16 pb-16">
      <div>
        <div className="max-w-5xl m-auto">
          <article className="flex flex-col items-center gap-4 relative m-auto w-fit">
            <div className="relative ">
              <h1 className="uppercase text-4xl md:text-5xl text-primary font-normal">
                Empresa
              </h1>
              <div className="absolute w-40 h-9 bg-customGray-gray top-[.6rem] left-4 -z-10 custom-border-r md:w-56 md:left-7 md:top-[.8rem]"></div>
            </div>

            <Link
              to="/cliente"
              className="uppercase cursor-pointer block font-medium text-sm text-primary hover:text-customBlue-whiteBlue regular-transition z-10"
            >
              sou cliente
            </Link>
          </article>
        </div>

        <div className="mt-16">
          <div className="relative -z-10">
            <img
              src={businessHeroWebp}
              className="absolute w-full h-[24rem] block custom-object-fit lg:h-[35rem]"
              onError={(e) => {
                e.target.src = businessHero;
              }}
            />
          </div>

          <div className="max-w-5xl relative m-auto rect-container p-3 flex flex-col items-center gap-16 pt-[7rem]">
            <article className="rect-content rect-shadow relative gap-[5.5rem]">
              <h2 className="text-center font-bold text-[1.23rem]">
                Melhor experiência <br />
                de usuário
              </h2>

              <p className="text-center font-bold text-[1.23rem]">
                Aumento de conversão <br />
                de clientes
              </p>
            </article>

            <article className="rect-content left-rect rect-shadow relative gap-6">
              <h2 className="text-center font-bold text-base">
                Mantém informações atualizadas e verificadas de seus clientes.
              </h2>

              <p className="text-center font-bold text-base">
                Serve como um sistema de gerenciamento, facilitando a busca por
                informações.
              </p>

              <div className="regular-rect border absolute bottom-36 -left-32 hidden md:block lg:-left-36"></div>
            </article>
          </div>

          <div className="m-auto max-w-5xl mt-8 p-3 flex flex-col items-center relative">
            <picture className="hero-image2-container">
              <img
                src={businessHero2Webp}
                alt="Equipe trabalhando juntos."
                className="w-full max-w-[45rem] object-cover"
                onError={(e) => {
                  e.target.src = businessHero2;
                }}
              />
            </picture>

            <div className="regular-rect border absolute -top-3 right-8 regular-rect-img2-first md:right-24 lg:right-52"></div>
            <div className="regular-rect border absolute -bottom-16 left-8 md:left-40 lg:left-72"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
