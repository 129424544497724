import { useEffect, useState } from "react";
import { sendRequest } from "../../../util/util";
import ComponentLoading from "../../../components/ComponentLoading";
import UpdateSuccess from "../../../components/UpdateSuccess";

export default function AllowSubs() {
  const [businessAllowInfosRequests, setBusinessAllowInfosRequests] = useState(
    () => []
  );

  const [isLoading, setIsLoading] = useState(() => true);
  const [isSuccess, setIsSuccess] = useState(() => false);

  const businessAllowInfosRequestsRows = businessAllowInfosRequests.map(
    (requestData, index) => {
      return (
        <BusinessAllowInfosRequets
          key={index}
          businessData={requestData.businessData}
          businessId={requestData.businessId}
          setIsLoading={setIsLoading}
          setIsSuccess={setIsSuccess}
          setBusinessAllowInfosRequests={setBusinessAllowInfosRequests}
        />
      );
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    async function handleRequest() {
      const requestData = {
        method: "GET",
        url: "clients/allow-infos-requests",
      };

      setIsLoading(() => true);

      try {
        const response = await sendRequest(requestData);

        if (!response.success) {
          window.alert(
            "Houve um erro no servidor, tente novamente mais tarde."
          );
          return;
        }

        setBusinessAllowInfosRequests(() => {
          return response.data;
        });
      } catch (error) {
        window.alert("Houve um erro no servidor, tente novamente mais tarde.");
      } finally {
        setIsLoading(() => false);
      }
    }

    handleRequest();
  }, []);

  return (
    <div className="pt-11">
      <article className="bg-customGray-profileGray p-10 flex flex-col gap-7">
        <h1 className="text-center text-primary font-bold text-lg sm:text-2xl lg:text-3xl">
          Empresas que gostariam das suas informações
        </h1>

        <div className="bg-white relative flex p-5 pt-10 pb-10 flex-col gap-6 w-full rounded-2xl">
          {isLoading ? (
            <ComponentLoading />
          ) : (
            <div>
              {businessAllowInfosRequests.length >= 1 ? (
                <div className="overflow-auto">
                  <table className="text-left min-w-fit w-full">
                    <thead className="text-primary font-bold text-nowrap text-lg">
                      <tr>
                        <th className="p-2 pr-4 pl-4"></th>
                        <th className="p-2 pr-4 pl-4">Nome:</th>
                        <th className="p-2 pr-4 pl-4">CNPJ</th>
                        <th className="p-2 pr-4 pl-4">Informações desejadas</th>
                        <th className="p-2 pr-4 pl-4">Ações</th>
                      </tr>
                    </thead>

                    <tbody className="text-nowrap text-gray-500 font-medium">
                      {businessAllowInfosRequestsRows}
                    </tbody>
                  </table>
                </div>
              ) : (
                <h3 className="text-lg text-center sm:text-2xl font-bold text-red-500">
                  Nenhuma autorização pendente encontrada.
                </h3>
              )}
            </div>
          )}
        </div>
      </article>

      {isSuccess && <UpdateSuccess />}
    </div>
  );
}

function BusinessAllowInfosRequets(props) {
  const requiredInfos = JSON.parse(props.businessData.requiredInfos);

  let newRequiredInfos = requiredInfos.map((info) => {
    if (info === "name") {
      return "Nome";
    } else if (info === "email") {
      return "E-mail";
    } else if (info === "cpf") {
      return "CPF";
    } else if (info === "phone") {
      return "Telefone";
    } else if (info === "cep") {
      return "CEP";
    } else if (info === "birthday") {
      return "Nascimento";
    }
  });

  newRequiredInfos = JSON.stringify(newRequiredInfos)
    .replace(/\\"/g, '"')
    .replace(/",/g, '", ')
    .replace(/\["/g, '[ "')
    .replace(/"\]/g, '" ]');

  return (
    <tr>
      <td className="p-4 pr-4 pl-4">
        <div>
          <picture className="w-24 block">
            <img
              className="w-full aspect-square rounded-full object-cover"
              src={props.businessData.img}
            />
          </picture>
        </div>
      </td>
      <td className="p-4 pr-4 pl-4">{props.businessData.name}</td>
      <td className="p-4 pr-4 pl-4">{props.businessData.cnpj}</td>
      <td className="p-4 pr-4 pl-4">{newRequiredInfos}</td>
      <td className="p-4 pr-4 pl-4">
        <button
          data-businessid={props.businessId}
          onClick={(event) =>
            handleAllowRequest(
              event,
              props.setIsLoading,
              props.setBusinessAllowInfosRequests,
              props.setIsSuccess
            )
          }
          type="button"
          className="text-sm text-white font-medium rounded-lg p-2 pr-6 pl-6 hover:bg-primary transition-all duration-300 text-nowrap bg-customBlue-darkBlue"
        >
          Autorizar
        </button>
      </td>
    </tr>
  );
}

function handleAllowRequest(
  event,
  setIsLoading,
  setBusinessAllowInfosRequests,
  setIsSuccess
) {
  const businessId = event.target.dataset.businessid;

  async function sendAllowRequest(
    setIsLoading,
    setBusinessAllowInfosRequests,
    setIsSuccess
  ) {
    const requestData = {
      method: "POST",
      url: `clients/allow-infos-requests?businessId=${businessId}`,
    };

    setIsLoading(() => true);

    try {
      const response = await sendRequest(requestData);

      if (!response.success) {
        window.alert("Houve um erro no servidor, tente novamente mais tarde.");
        return;
      }

      setBusinessAllowInfosRequests(() => {
        return response.data;
      });

      setIsSuccess(() => true);

      setTimeout(() => {
        setIsSuccess(() => false);
      }, 4000);
    } catch (error) {
      window.alert("Houve um erro no servidor, tente novamente mais tarde.");
    } finally {
      window.scrollTo(0, 0);
      setIsLoading(() => false);
    }
  }

  sendAllowRequest(setIsLoading, setBusinessAllowInfosRequests, setIsSuccess);
}
