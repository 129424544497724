import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home/Home";
import Client from "./client/Client";
import Business from "./business/Business";
import AppLayout from "./AppLayout";
import ClientLogin from "./auth/ClientLogin";
import BusinessLogin from "./auth/BusinessLogin";
import ClientProfile from "./profile/client/ClientProfile";
import BusinessProfile from "./profile/business/BusinessProfile";
import Auth from "../middlewares/auth/Auth";
import LoginMiddleware from "../middlewares/auth/LoginMiddleware";
import ProfileLayout from "./profile/ProfileLayout";
import AllowSubs from "./profile/client/AllowSubs";
import Subscription from "./profile/client/Subscription";
import BusinessClients from "./profile/business/BusinessClients";
import SendSubscription from "./profile/business/SendSubscription";
import EditClientProfile from "./profile/client/EditClientProfile";
import LogOut from "../components/auth/LogOut";
import ClientSignUp from "./auth/ClientSignUp";
import ActiveUser from "./auth/ActiveUser";
import BusinessSignUp from "./auth/BusinessSignUp";
import EditBusinessProfile from "./profile/business/EditBusinessProfile";
import CommunityHome from "../components/community/home/CommunityHome";
import PostHome from "../components/community/post/PostHome";
import AppPolicy from "./policy/AppPolicy";
import PravicyPolicy from "./policy/PravicyPolicy";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/sulkey-active-user" element={<ActiveUser />} />

          <Route index element={<Home />} />
          <Route path="cliente" element={<Client />} />
          <Route path="cliente/registrar-se" element={<ClientSignUp />} />

          <Route element={<LoginMiddleware />}>
            <Route path="cliente-login" element={<ClientLogin />} />
            <Route path="empresa-login" element={<BusinessLogin />} />
          </Route>

          <Route path="empresa" element={<Business />} />
          <Route path="empresa/registrar-se" element={<BusinessSignUp />} />

          <Route element={<Auth />}>
            <Route element={<ProfileLayout />}>
              <Route path="perfil-cliente">
                <Route index element={<ClientProfile />} />
                <Route path="meus-cadastros" element={<Subscription />} />
                <Route path="autorizacoes" element={<AllowSubs />} />
                <Route
                  path="editar-informacoes"
                  element={<EditClientProfile />}
                />
              </Route>

              <Route path="perfil-empresa">
                <Route index element={<BusinessProfile />} />
                <Route
                  path="editar-informacoes"
                  element={<EditBusinessProfile />}
                />
                <Route path="meus-clientes" element={<BusinessClients />} />
                <Route
                  path="requerir-informacoes"
                  element={<SendSubscription />}
                />
              </Route>

              <Route path="/sair" element={<LogOut />} />
            </Route>
          </Route>

          <Route path="comunidade" element={<CommunityHome />} />
          <Route path="comunidade/post/:id" element={<PostHome />} />

          <Route path="termos-de-uso" element={<AppPolicy />} />
          <Route path="politica-de-privacidade" element={<PravicyPolicy />} />

          <Route path="*" element={<h1> Not found </h1>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
