import rect from "../../assets/images/rect.png";

export default function ClientMoreInfo() {
  return (
    <section className="sm:-mt-[13rem] pb-[10rem]">
      <div className="max-w-5xl m-auto">
        <div className="flex flex-col items-center">
          <article className="relative p-3 flex flex-col items-center w-fit gap-6 pt-10 pb-10">
            <h2 className="font-medium text-base text-primary z-10 max-w-[42ch] text-center lg:text-lg">
              SulKey veio com a missão de revolucionar a maneira como todos nós
              nos cadastramos.
            </h2>

            <h3 className="font-medium text-base z-10 text-primary lg:text-lg text-center">
              Integrando clientes e empresas em um único sistema.
            </h3>

            <div className="absolute w-[60%] max-w-[17.8rem] lg:max-w-[20.4rem] -z-10 h-[10rem] border border-customBlue-whiteBlue top-0 lg:top-3 left-2 moreInfo-t-square-position"></div>
            <div className="absolute w-[60%] max-w-[17.8rem] lg:max-w-[20.4rem] -z-10 h-[10rem] border border-customBlue-whiteBlue -bottom-5 right-2 moreInfo-b-square-position"></div>
          </article>

          <article className="mt-[10rem] relative p-3 ">
            <div className="regular-rect border absolute -top-10 left-2 sm:-left-12"></div>

            <h2 className="max-w-[32ch] z-10 text-center font-medium text-base text-primary lg:text-lg">
              Com um único cadastro, você pode se registrar em qualquer lugar
              apenas informando seu nome e autorizando a transferência das
              informações.
            </h2>

            <img
              src={rect}
              className="absolute w-[22rem] -z-10 -top-8 -left-1 lg:left-5 lg:w-[23rem]"
            />
            <div className="regular-rect border absolute -bottom-12 right-2 sm:right-7 sm:-bottom-28"></div>
          </article>
        </div>
      </div>
    </section>
  );
}
