export default function Loading() {
  return (
    <div className="fixed flex flex-col items-center justify-center top-0 bottom-0 left-0 right-0 z-50 bg-[rgba(0,0,0,0.3)]">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
