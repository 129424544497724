export function phoneValidation(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\D/g, "");

  if (!(phoneNumber.length >= 10 && phoneNumber.length <= 11)) {
    return false;
  }

  if (phoneNumber.length === 11 && parseInt(phoneNumber[2]) !== 9) {
    return false;
  }

  const isRepeatingNumber = Array.from(
    { length: 10 },
    (_, n) =>
      phoneNumber === Array(11).join(n) || phoneNumber === Array(12).join(n)
  ).some(Boolean);
  if (isRepeatingNumber) {
    return false;
  }

  const validAreaCodes = [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
    37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63,
    65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
    89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  ];

  if (!validAreaCodes.includes(parseInt(phoneNumber.substring(0, 2)))) {
    return false;
  }

  if (new Date().getFullYear() < 2017) {
    return true;
  }

  if (
    phoneNumber.length === 10 &&
    ![2, 3, 4, 5, 7].includes(parseInt(phoneNumber[2]))
  ) {
    return false;
  }

  return true;
}

export async function sendRequest(requestData) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (localStorage.getItem("token")) {
      const userToken = localStorage.getItem("token");

      headers["Authorization"] = `Bearer ${userToken}`;
    }

    if (requestData.token) {
      headers["Authorization"] = `Bearer ${requestData.token}`;
    }

    let serverPath = "https://server.sulkey.com.br/api/v1/";

    if (requestData.isAdminServer) {
      serverPath = "https://server-admin.sulkey.com.br/api/v1/";
    }

    const response = await fetch(`${serverPath}${requestData.url}`, {
      method: requestData.method,
      body: JSON.stringify(requestData.body),
      headers: headers,
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    return `There was a request error - ${error}`;
  }
}
