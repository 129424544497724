import { useOutletContext, Link } from "react-router-dom";
import ListInfo from "../../../components/profile/ListInfo";

export default function ClientProfile() {
  const userData = useOutletContext();

  const infoNames = getInfoNames();
  const infoValues = getInfoValues(userData);

  const infoNamesElement = infoNames.map((name, index) => {
    return <ListInfo value={name} key={index} />;
  });

  const infoValuesElement = infoValues.map((value, index) => {
    return <ListInfo value={value} key={index} />;
  });

  return (
    <div className="pt-11">
      <article className="bg-customGray-profileGray p-3 pt-6 pb-6 flex flex-col gap-10 sm:p-6 items-center lg:p-10">
        <h1 className="text-2xl text-center font-bold text-primary sm:text-3xl lg:text-4xl">
          Minhas informações
        </h1>

        <div className="overflow-auto w-fit max-w-full p-2">
          <div className="flex gap-4 sm:gap-10 text-sm w-fit sm:text-base lg:text-lg justify-center">
            <ol className="flex flex-col gap-2 text-primary font-bold">
              {infoNamesElement}
            </ol>

            <ol className="flex flex-col gap-2 text-customBlue-darkBlue font-medium">
              {infoValuesElement}
            </ol>
          </div>
        </div>

        <Link
          to="/perfil-cliente/editar-informacoes"
          className="self-end p-2 pl-6 pr-6 text-nowrap text-sm rounded-lg sm:text-base bg-customBlue-darkBlue hover:bg-blue-600 transition duration-200 text-white font-medium cursor-pointer"
        >
          Editar informações
        </Link>
      </article>
    </div>
  );
}

function getInfoNames() {
  return [
    "Nome:",
    "CPF:",
    "Email:",
    "Telefone:",
    "CEP:",
    "Nascimento:",
    "Sulkey ID:",
  ];
}

function getInfoValues(userData) {
  return [
    userData.name,
    userData.cpf,
    userData.email,
    userData.phone,
    userData.cep,
    userData.birthday,
    userData.sulkeyId,
  ];
}
