import { useEffect } from "react";
import ClientHero from "../../components/client/ClientHero";
import ClientMoreInfo from "../../components/client/ClientMoreInfo";
import ClientForm from "../../components/client/ClientForm";

export default function Client() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <ClientHero />

      <ClientMoreInfo />

      <ClientForm />
    </>
  );
}
