import { useState, useEffect } from "react";
import Loading from "../../../components/Loading";
import { sendRequest } from "../../../util/util";

export default function SendSubscription() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState(() => {
    return {
      data: {
        userIdentifier: "",
      },
      status: {
        success: false,
        errors: {
          userIdentifierError: {
            error: false,
            msg: "",
          },
        },
      },
    };
  });

  const [isLoading, setIsLoading] = useState(() => false);

  return (
    <div className="pt-11">
      <article className="bg-customGray-profileGray p-3 pt-16 pb-16 flex flex-col gap-10">
        <h1 className="text-center text-lg sm:text-2xl text-primary font-bold">
          Preencha o para pedir o cadastro.
        </h1>

        <form
          onSubmit={(event) =>
            handleSubmit(event, formData, setFormData, setIsLoading)
          }
          noValidate
          className="flex flex-col items-center gap-6 w-full"
        >
          <div className="w-full flex flex-col items-center gap-3">
            <input
              onChange={(event) => handleChange(event, setFormData)}
              value={formData.data.userIdentifier}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="userIdentifier"
              placeholder="CPF ou SulKeyID"
            />

            {formData.status.errors.userIdentifierError.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.userIdentifierError.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col items-center gap-3">
            <button
              className="p-2 hover:border-primary border text-base lg:text-lg cursor-pointer w-full font-bold text-white bg-customBlue-darkBlue transition-all duration-200 rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="submit"
            >
              Pedir Cadastro
            </button>

            {formData.status.success && (
              <p className="text-green-500 text-center font-medium text-base lg:text-lg">
                Solicitação de acesso enviada com sucesso!
              </p>
            )}
          </div>
        </form>

        {isLoading && <Loading />}
      </article>
    </div>
  );
}

function handleChange(event, setFormData) {
  const target = event.target;

  setFormData((prevFormData) => {
    return {
      ...prevFormData,
      data: {
        ...prevFormData.data,
        [target.name]: target.value,
      },
    };
  });
}

function handleSubmit(event, formData, setFormData, setIsLoading) {
  event.preventDefault();

  setIsLoading(() => true);

  handleRequest(formData.data, setFormData, setIsLoading);
}

async function handleRequest(data, setFormData, setIsLoading) {
  const requestData = {
    method: "POST",
    url: "businesses/send-request",
    body: data,
  };

  try {
    const response = await sendRequest(requestData);

    if (!response.success) {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          status: {
            success: false,
            errors: {
              userIdentifierError: {
                error: true,
                msg: response.errors.userIdentifier,
              },
            },
          },
        };
      });

      return;
    }

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        status: {
          success: true,
          errors: {
            userIdentifierError: {
              error: false,
              msg: "",
            },
          },
        },
      };
    });

    setTimeout(() => {
      setFormData((prevFormData) => {
        return {
          data: {
            userIdentifier: "",
          },
          status: {
            success: false,
            errors: {
              userIdentifierError: {
                error: false,
                msg: "",
              },
            },
          },
        };
      });
    }, 4000);
  } catch (error) {
    window.alert("Houve um problema no servidor, tente novamente mais tarde.");
  } finally {
    setIsLoading(() => false);
  }
}
