import { useEffect, useState } from "react";
import { sendRequest } from "../../../../util/util";
import { Link } from "react-router-dom";
import ComponentLoading from "../../../ComponentLoading";

export default function TimeLine() {
  const [timelineData, setTimelineData] = useState(() => []);
  const [timelineIsLoading, setTimelineIsLoading] = useState(() => true);

  useEffect(() => {
    async function handleGetPostsRequest() {
      setTimelineIsLoading(() => true);

      const requestData = {
        method: "GET",
        url: "public/posts",
        isAdminServer: true,
      };

      try {
        const response = await sendRequest(requestData);

        if (!response.success) {
          return;
        }

        setTimelineData(() => response.data);
      } catch (error) {
        window.alert(`Houve um erro no servidor ${error}`);
      } finally {
        setTimelineIsLoading(() => false);
      }
    }

    handleGetPostsRequest();
  }, []);

  const infoLeft = timelineData.filter((data, index) => {
    return !(index % 2);
  });

  const infoRight = timelineData.filter((data, index) => {
    return index % 2;
  });

  const infoLeftElements = infoLeft.map((info) => {
    return (
      <TimeInfo
        textLeft={false}
        key={info.id}
        title={info.title}
        content={info.content}
        imgUrl={info.thumbnail}
        datetime={info.updatedAt}
        id={info.id}
      />
    );
  });

  const infoRightElements = infoRight.map((info) => {
    return (
      <TimeInfo
        textLeft={true}
        key={info.id}
        title={info.title}
        content={info.content}
        imgUrl={info.thumbnail}
        datetime={info.updatedAt}
        id={info.id}
      />
    );
  });

  return (
    <section className="pt-8 pb-10">
      <article className="flex flex-col items-center gap-12 p-5">
        <h2 className="text-center text-4xl text-communityprimary font-bold">
          Nossa timeline
        </h2>

        {timelineIsLoading ? (
          <div className="relative p-10">
            <ComponentLoading customBg="bg-none" />
          </div>
        ) : timelineData.length >= 1 ? (
          <div className="overflow-auto border border-communityprimary rounded-lg w-full p-5 pt-16 pb-16 max-w-6xl">
            <div className="flex justify-center w-fit m-auto">
              <div className="pr-14 pt-10 relative border-r-8 border-secondary w-fit flex flex-col gap-14 ">
                {infoLeftElements}

                <div className="absolute w-6 aspect-square rounded-full bg-secondary -right-4 -top-3"></div>
                <div className="absolute w-6 aspect-square rounded-full bg-secondary -right-4 -bottom-3"></div>
              </div>
              <div className="pt-44 pl-14 w-fit flex flex-col gap-14 ">
                {infoRightElements}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-2xl font-bold text-red-500">
              Nenhum histórico encontrado.
            </p>
          </div>
        )}
      </article>
    </section>
  );
}

function TimeInfo(props) {
  const domParser = new DOMParser();
  const contentDom = domParser.parseFromString(props.content, "text/html");

  const descriptionElements = contentDom.querySelectorAll("p");

  let description = "Nenhuma descrição encontrada.";
  let descriptionError = true;

  if (descriptionElements) {
    const descriptionsArray = Array.from(descriptionElements);

    for (const descriptionElement of descriptionsArray) {
      const descriptionText = descriptionElement.innerText;

      if (descriptionText.length >= 1) {
        descriptionError = false;
        description = descriptionText;
        break;
      }
    }
  }

  const datetime = new Date(props.datetime);
  const formattedDate = datetime.toLocaleDateString("en-GB");

  return (
    <div
      className={`flex w-[28rem] gap-8 relative ${
        props.textLeft ? "flex-row-reverse" : ""
      } `}
    >
      <article
        className={`flex flex-col gap-2 ${
          props.textLeft ? "text-left" : "text-right"
        } `}
      >
        <mark className="text-communityprimary font-bold text-base">
          {formattedDate}
        </mark>

        <h2 className="text-communityprimary font-bold text-2xl max-w-[25ch] line-clamp-2">
          {props.title}
        </h2>

        <p
          className={`${
            descriptionError
              ? "text-red-500 font-bold"
              : "text-communityprimary font-normal"
          } text-base max-w-[40ch] line-clamp-3`}
        >
          {description}
        </p>

        <Link
          to={`post/${props.id}`}
          className="font-medium cursor-pointer text-communityprimary hover:text-secondary transition duration-200 text-base"
        >
          Saber mais
        </Link>
      </article>

      <picture className="w-28 z-10">
        <img
          src={props.imgUrl}
          className="w-28 aspect-square object-cover rounded-full border-secondary border-8"
        />
      </picture>

      <div
        className={`absolute w-16 h-3 bg-secondary -right-14 top-9 z-0 ${
          props.textLeft && "-left-14"
        } `}
      ></div>
    </div>
  );
}
