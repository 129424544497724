import { useEffect } from "react";

export default function AppPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <article className="p-3 py-16 flex flex-col gap-10 lg:gap-16 m-auto max-w-6xl">
        <article className="flex flex-col items-center gap-4">
          <h1 className="text-2xl md:text-4xl text-center text-primary font-bold">
            TERMOS DE USO <br /> SULKEY
          </h1>
          <p className="text-base font-medium text-center text-communityprimary md:max-w-[70ch]">
            Estes Termos de Uso visam garantir a transparência, privacidade e
            segurança durante a utilização do serviço Sulkey. Agradecemos por
            confiar em nossos serviços.
          </p>
        </article>

        <section className="app-policy">
          <article>
            <h2>CLÁUSULA 1 ~ DO OBJETO</h2>

            <ol>
              <li>
                <p>
                  <mark>1.1.</mark> A Sulkey oferece um sistema de cadastro
                  simplificado, onde os usuários podem se cadastrar usando
                  apenas o ID do sistema em empresas parceiras.
                </p>
              </li>
              <li>
                <p>
                  <mark>1.2.</mark> Valoriza-se a simplicidade e a praticidade,
                  utilizando linguagem acessível, evitando complexidades nos
                  Termos de Uso, e permitimos o download ou envio por e-mail
                  para fácil acesso dos <mark> USUÁRIOS.</mark>
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>CLÁUSULA 2 ~ DA ACEITAÇÃO</h2>

            <ol>
              <li>
                <p>
                  <mark>2.1.</mark> O presente Termo estabelece obrigações
                  contratadas de livre e espontânea vontade, por tempo
                  indeterminado, entre a plataforma e os{" "}
                  <mark> USUÁRIOS. </mark>
                </p>
              </li>
              <li>
                <p>
                  <mark>2.2.</mark> Ao utilizar a plataforma o usuário aceita
                  integralmente as presentes normas e compromete-se a
                  observá-las, sob o risco de aplicação das penalidades
                  cabíveis.
                </p>
              </li>
              <li>
                <p>
                  <mark>2.3.</mark> A aceitação do presente instrumento é
                  imprescindível para o acesso e para a utilização de quaisquer
                  serviços fornecidos pela empresa. Caso não concorde com as
                  disposições deste instrumento, o usuário não deve utilizá-los.
                </p>
              </li>
              <li>
                <p>
                  <mark>2.4.</mark> A Sulkey compromete-se a utilizar uma
                  linguagem simples e acessível na redação dos termos de uso,
                  evitando jargões técnicos ou jurídicos que possam dificultar a
                  compreensão por parte dos usuários.
                </p>
              </li>
              <li>
                <p>
                  <mark>2.5.</mark> Além disso, os termos de uso serão
                  apresentados de forma destacada e facilmente acessível na
                  plataforma da Sulkey, possibilitando aos usuários consultá-los
                  a qualquer momento.
                </p>
              </li>
              <li>
                <p>
                  <mark>2.6.</mark> Caso haja qualquer dúvida ou dificuldade de
                  compreensão em relação aos termos de uso, os usuários poderão
                  contatar a Sulkey por meio dos canais de comunicação
                  disponibilizados para esclarecimentos adicionais."
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>CLÁUSULA 3 ~ DO SERVIÇO</h2>

            <ol>
              <li>
                <p>
                  <mark>3.1.</mark> O serviço da Sulkey é essencialmente sobre a
                  coleta e compartilhamento de dados pessoais, estando o
                  <mark> USUÁRIO </mark>ciente de tal função.
                </p>
              </li>
              <li>
                <p>
                  <mark>3.2.</mark> É imperativo ressaltar que, em conformidade
                  com a Lei Geral de Proteção de Dados (LGPD) vigente no Brasil,
                  a Sulkey compromete-se a obter o consentimento explícito do
                  usuário antes de coletar e processar quaisquer dados pessoais.
                </p>
              </li>
              <li>
                <p>
                  <mark>3.2.1.</mark> O usuário será devidamente informado sobre
                  as finalidades da coleta de dados, bem como sobre quais
                  informações serão solicitadas e a forma como serão utilizadas
                  pela Sulkey.
                </p>
              </li>
              <li>
                <p>
                  <mark>3.2.2.</mark> Ao consentir com a presente cláusula, o
                  usuário reconhece e concorda que seus dados pessoais serão
                  coletados e processados exclusivamente para os fins descritos,
                  em conformidade com as disposições da LGPD.
                </p>
              </li>
              <li>
                <p>
                  <mark>3.2.3.</mark> A Sulkey compromete-se a adotar medidas
                  técnicas e organizacionais adequadas para garantir a segurança
                  e a proteção dos dados pessoais dos usuários, visando evitar
                  acessos não autorizados, perdas ou qualquer forma de
                  tratamento indevido das informações coletadas.
                </p>
              </li>
              <li>
                <p>
                  <mark>3.2.4.</mark> Quaisquer dúvidas ou solicitações
                  relacionadas à proteção de dados pessoais podem ser
                  encaminhadas à Sulkey por meio dos canais de comunicação
                  disponibilizados para esse fim.
                </p>
              </li>
              <li>
                <p>
                  <mark>3.3.</mark> Na plataforma os serviços oferecidos estão
                  descritos e apresentados com o maior grau de exatidão,
                  contendo informações sobre suas características, preço, prazos
                  de validade e origem, entre outros dados, bem como sobre os
                  riscos que apresentam à segurança do <mark>USUÁRIO.</mark>
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>CLÁUSULA 4 ~ DO CANCELAMENTO</h2>

            <ol>
              <li>
                <p>
                  <mark>4.1.</mark> O <mark>USUÁRIO</mark> poderá cancelar a
                  contratação dos serviços a qualquer momento, devendo, caso
                  queira, informar o pedido de cancelamento via e-mail
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    4.2. Procedimento de Cancelamento e Efeitos para o Usuário:
                  </mark>
                </p>
              </li>
              <li>
                <p>
                  <mark>4.2.1.</mark> A Sulkey reconhece a importância de
                  fornecer informações claras e detalhadas sobre o procedimento
                  de cancelamento dos serviços para os usuários.
                </p>
              </li>
              <li>
                <p>
                  <mark>4.2.2.</mark> O usuário poderá solicitar o cancelamento
                  dos serviços a qualquer momento, conforme estabelecido na
                  Cláusula 4.1, mediante envio de solicitação por e-mail para o
                  endereço especificado pela Sulkey.
                </p>
              </li>
              <li>
                <p>
                  <mark>4.2.3.</mark> Após o recebimento da solicitação de
                  cancelamento, a Sulkey compromete-se a processar a solicitação
                  no prazo máximo de [XX] dias úteis e a fornecer uma
                  confirmação por escrito ao usuário, indicando a efetivação do
                  cancelamento e eventuais medidas a serem tomadas pelo usuário,
                  se aplicável.
                </p>
              </li>
              <li>
                <p>
                  <mark>4.2.4.</mark> É importante ressaltar que o cancelamento
                  dos serviços poderá implicar na perda de acesso a determinadas
                  funcionalidades da plataforma da Sulkey, bem como na exclusão
                  permanente dos dados do usuário armazenados nos sistemas da
                  empresa, conforme permitido pela legislação aplicável e pelas
                  políticas internas da Sulkey.
                </p>
              </li>
              <li>
                <p>
                  <mark>4.2.5.</mark> O usuário será devidamente informado sobre
                  quaisquer efeitos decorrentes do cancelamento dos serviços,
                  visando garantir uma tomada de decisão consciente e informada.
                </p>
              </li>
              <li>
                <p>
                  <mark>4.2.6.</mark> Quaisquer dúvidas ou questões relacionadas
                  ao procedimento de cancelamento podem ser encaminhadas à
                  Sulkey por meio dos canais de comunicação disponibilizados
                  para esse fim.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>
              CLÁUSULA 5 ~ DA PRIVACIDADE E SEGURANÇA DOS DADOS PESSOAIS
              ARMAZENADOS
            </h2>

            <ol>
              <li>
                <p>
                  <mark>5.1.</mark> Comprometemo-nos a proteger a privacidade
                  dos USUÁRIOS. As informações coletadas serão tratadas com
                  segurança, conforme as leis locais de proteção de dados.
                </p>
              </li>
              <li>
                <p>
                  <mark>5.2.</mark> Além do presente Termo, o usuário deverá
                  consentir com as disposições contidas na respectiva Política
                  de Privacidade a ser apresentada a todos os interessados
                  dentro da interface da plataforma.
                </p>
              </li>
              <li>
                <p>
                  <mark>5.3. Medidas de Segurança de Dados: </mark>
                </p>
              </li>
              <li>
                <p>
                  <mark>5.3.1.</mark> A Sulkey compromete-se a implementar e
                  manter medidas de segurança adequadas para proteger os dados
                  pessoais dos usuários contra acessos não autorizados, uso
                  indevido, divulgação, alteração ou destruição não autorizada.
                </p>
              </li>
              <li>
                <p>
                  <mark>5.3.2.</mark> Tais medidas de segurança incluirão, mas
                  não se limitarão a, criptografia de dados, controle de acesso
                  restrito aos dados, uso de firewalls e sistemas de detecção de
                  intrusos, monitoramento regular dos sistemas, treinamento de
                  pessoal em segurança da informação e outras práticas
                  recomendadas pela legislação aplicável e pelas melhores
                  práticas do setor.
                </p>
              </li>
              <li>
                <p>
                  <mark>5.3.3.</mark> Além disso, a Sulkey compromete-se a
                  realizar avaliações periódicas de risco e auditorias de
                  segurança para identificar e mitigar quaisquer
                  vulnerabilidades ou ameaças à segurança dos dados dos
                  usuários.
                </p>
              </li>
              <li>
                <p>
                  <mark>5.3.4.</mark> A Sulkey manterá registros adequados das
                  medidas de segurança implementadas, bem como de quaisquer
                  incidentes de segurança de dados que possam ocorrer, e tomará
                  as medidas necessárias para remediar tais incidentes e evitar
                  sua recorrência.
                </p>
              </li>
              <li>
                <p>
                  <mark>5.3.5.</mark> Quaisquer dúvidas ou preocupações
                  relacionadas à segurança dos dados pessoais dos usuários podem
                  ser encaminhadas à Sulkey por meio dos canais de comunicação
                  disponibilizados para esse fim.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>CLÁUSULA 6 ~COMPARTILHAMENTO DE DADOS</h2>

            <ol>
              <li>
                <p>
                  <mark>6.1.</mark> Nenhuma informação é manejada sem
                  autorização do
                  <mark> USUÁRIO </mark>
                  via e-mail ou App.
                </p>
              </li>
              <li>
                <p>
                  <mark>6.2.</mark> Todas as informações compartilhadas com
                  terceiros exigem autorização explícita do
                  <mark> USUÁRIO</mark>. Durante o processo de autorização,
                  todas as informações a serem compartilhadas serão claramente
                  comunicadas.
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    6.3 - Processo Transparente de Autorização para
                    Compartilhamento de Dados:
                  </mark>
                </p>
              </li>
              <li>
                <p>
                  <mark>6.3.1.</mark> A Sulkey compromete-se a garantir que o
                  processo de autorização para o compartilhamento de dados seja
                  claro, transparente e facilmente compreensível para os
                  usuários.
                </p>
              </li>
              <li>
                <p>
                  <mark>6.3.2.</mark> Antes de solicitar a autorização do
                  usuário para compartilhar suas informações com terceiros, a
                  Sulkey fornecerá uma explicação clara e detalhada sobre os
                  fins para os quais os dados serão compartilhados, bem como
                  sobre quais informações específicas serão compartilhadas.
                </p>
              </li>
              <li>
                <p>
                  <mark>6.3.3.</mark> O usuário terá a oportunidade de revisar e
                  compreender completamente os termos do compartilhamento de
                  dados antes de fornecer sua autorização. Além disso, a Sulkey
                  não condicionará o uso de seus serviços à concessão de
                  autorização para o compartilhamento de dados, exceto nos casos
                  permitidos por lei e de acordo com as disposições desta
                  política.
                </p>
              </li>
              <li>
                <p>
                  <mark>6.3.4.</mark> A autorização do usuário para o
                  compartilhamento de dados será obtida de forma explícita e por
                  meio de um processo claro, como uma opção de seleção ou botão
                  específico de consentimento, garantindo assim que o usuário
                  tenha total controle sobre suas informações pessoais.
                </p>
              </li>
              <li>
                <p>
                  <mark>6.3.5.</mark> A Sulkey manterá registros adequados das
                  autorizações de compartilhamento de dados concedidas pelos
                  usuários, incluindo informações sobre os fins do
                  compartilhamento, os terceiros envolvidos e quaisquer outras
                  informações relevantes.
                </p>
              </li>
              <li>
                <p>
                  <mark>6.3.6.</mark> Quaisquer dúvidas ou preocupações
                  relacionadas ao processo de autorização para o
                  compartilhamento de dados podem ser encaminhadas à Sulkey por
                  meio dos canais de comunicação disponibilizados para esse fim.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>CLÁUSULA 7 ~ DAS ALTERAÇÕES DO TERMO DE USO</h2>

            <ol>
              <li>
                <p>
                  <mark>7.1.</mark> Os itens descritos no presente instrumento
                  poderão sofrer alterações, unilateralmente e a qualquer tempo,
                  por parte da Sulkey, para adequar ou modificar os serviços,
                  bem como para atender novas exigências legais.
                </p>
              </li>
              <li>
                <p>
                  <mark>7.2.</mark> Os usuários serão notificados sobre
                  possíveis atualizações nos Termos de Uso. Recomendamos revisar
                  periodicamente para manter-se informado sobre quaisquer
                  mudanças.
                </p>
              </li>
              <li>
                <p>
                  <mark>7.3.</mark> O <mark>USUÁRIO</mark> poderá optar por
                  aceitar o novo conteúdo ou por cancelar o uso dos serviços,
                  caso seja assinante de algum serviço.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>CLÁUSULA 8 ~ DA CONCORDÂNCIA COM OS TERMOS</h2>

            <ol>
              <li>
                <p>
                  <mark>8.1.</mark> Ao utilizar o serviço Sulkey, o usuário
                  concorda com estes Termos de Uso. Mecanismos, como o clique no
                  botão de concordância, garantem que o usuário esteja ciente e
                  consinta com as condições estabelecidas.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.2.</mark> O “Aceite” aos presentes termos, dá ciência
                  de todas as Cláusulas do presente termo, renunciando à
                  alegação de vício de consentimento, previstos no art. 171, II
                  do Código Civil.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3 - Consentimento Explícito do Usuário:</mark>
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3.1.</mark> A Sulkey reconhece a importância de obter
                  o consentimento explícito do usuário para a aceitação dos
                  presentes Termos de Uso.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3.2.</mark> Ao utilizar os serviços da Sulkey, o
                  usuário será apresentado de forma clara e acessível aos Termos
                  de Uso, os quais deverão ser lidos e compreendidos antes de
                  prosseguir com o uso dos serviços.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3.3.</mark> O consentimento do usuário será obtido de
                  maneira explícita e ativa, por meio de uma ação clara e
                  afirmativa, como o clique em um botão específico indicando o
                  consentimento com os Termos de Uso ou a seleção de uma caixa
                  de verificação indicando a concordância.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3.4.</mark> A Sulkey não utilizará práticas que possam
                  induzir o usuário a fornecer consentimento de forma
                  involuntária ou não informada.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3.5.</mark> A Sulkey manterá registros adequados dos
                  consentimentos obtidos dos usuários em relação aos Termos de
                  Uso, incluindo informações sobre o momento e a forma como o
                  consentimento foi dado.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3.6.</mark> Quaisquer dúvidas ou preocupações
                  relacionadas ao consentimento do usuário para os Termos de Uso
                  podem ser encaminhadas à Sulkey por meio dos canais de
                  comunicação disponibilizados para esse fim.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>CLÁUSULA 9 ~ DO CONTATO E DÚVIDAS</h2>

            <ol>
              <li>
                <p>
                  <mark>9.1.</mark> Os <mark>USUÁRIOS</mark> podem entrar em
                  contato por WhatsApp, telefone, e-mail, tickets na plataforma,
                  comentários no blog de atualizações e redes sociais.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>CLÁUSULA 10 ~ DISPOSIÇÕES FINAIS</h2>

            <ol>
              <li>
                <p>
                  <mark>10.1.</mark> Recomendamos a busca de orientação jurídica
                  para esclarecimentos adicionais ou questões específicas
                  relacionadas aos Termos de Uso da Sulkey.
                </p>
              </li>
              <li>
                <p>
                  <mark>10.2.</mark> Para a solução de controvérsias decorrentes
                  do presente instrumento será aplicado integralmente o Direito
                  brasileiro.
                </p>
              </li>
              <li>
                <p>
                  <mark>10.3.</mark> Os eventuais litígios deverão ser
                  apresentados no foro da comarca em que se encontra a sede da
                  empresa.
                </p>
              </li>
            </ol>
          </article>
        </section>
      </article>
    </section>
  );
}
