import Main from "./main/Main";
import { useEffect } from "react";

export default function CommunityHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Main />
    </div>
  );
}
