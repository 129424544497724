import { Link } from "react-router-dom";
import clientHeroImage from "../../assets/images/client-hero-image.png";
import clientHeroImage2 from "../../assets/images/client-hero-image-2.png";

import clientHeroImageWebp from "../../assets/images/client-hero-image.webp";
import clientHeroImage2Webp from "../../assets/images/client-hero-image-2.webp";

export default function ClientHero() {
  return (
    <section className="pt-16 pb-16">
      <div className="max-w-5xl m-auto">
        <article className="flex flex-col gap-10 sm:gap-0 relative">
          <article className="flex flex-col items-center gap-4 relative m-auto w-fit">
            <div className="relative ">
              <h1 className="uppercase text-4xl md:text-5xl text-primary font-normal">
                Cliente
              </h1>
              <div className="absolute w-40 h-9 bg-customGray-gray top-[.6rem] left-4 -z-10 custom-border-r md:w-56 md:left-5 md:top-[.8rem]"></div>
            </div>

            <Link
              to="/empresa"
              className="uppercase cursor-pointer block font-medium text-sm text-primary hover:text-customBlue-whiteBlue regular-transition z-10"
            >
              sou empresa
            </Link>

            <div className="regular-rect border absolute top-14 -left-[20vw] sm:-left-44 lg:-left-[21rem]"></div>
          </article>

          <picture className="flex justify-end sm:relative sm:-top-14 z-0">
            <img
              src={clientHeroImageWebp}
              className="mobile-chop-img"
              onError={(e) => {
                e.target.src = clientHeroImage;
              }}
            />
          </picture>

          <div className="regular-rect border absolute -bottom-1 right-[4.3rem] sm:bottom-14 sm:right-[5.5rem]"></div>
        </article>

        <div>
          <article className="p-3 relative w-fit mt-7 sm:-top-20 pl-5">
            <p className="text-primary font-medium text-base lg:text-lg z-10">
              Já notou quanto tempo você leva <br />
              para fazer um cadastro?
            </p>

            <div className="absolute regular-rect border-l border-t -z-10 border-customBlue-whiteBlue top-0 left-[3.3rem] lg:top-1 lg:left-[3.6rem]"></div>
            <div className="absolute regular-rect border-b border-r -z-10 border-customBlue-whiteBlue -bottom-4 right-24 lg:right-[6.6rem]"></div>
          </article>
        </div>

        <div className="flex justify-end mt-5 sm:mt-0 sm:relative sm:-top-5">
          <article className="p-3 relative w-fit mt-7 sm:-top-20 pl-5">
            <p className="text-primary z-10 font-medium text-base lg:text-lg">
              Quantos cadastro você já fez <br />e vai fazer?
            </p>

            <div className="absolute regular-rect border-l border-t -z-10 border-customBlue-whiteBlue -top-1 left-[3.5rem] lg:left-[3.8rem] lg:top-0"></div>
            <div className="absolute regular-rect border-b border-r -z-10 border-customBlue-whiteBlue -bottom-2 right-[5.6rem] lg:right-[6.8rem]"></div>
          </article>
        </div>

        <div className="mt-10 relative w-fit sm:-top-[9.8rem]">
          <picture className="flex justify-start">
            <img
              src={clientHeroImage2Webp}
              className="mobile-chop-img"
              onError={(e) => {
                e.target.src = clientHeroImage2;
              }}
            />
          </picture>

          <div className="regular-rect border absolute top-8 -right-5 sm:-right-20"></div>
        </div>
      </div>
    </section>
  );
}
