import { useEffect, useState } from "react";
import { sendRequest } from "../../../../../util/util";
import { Link } from "react-router-dom";
import ComponentLoading from "../../../../ComponentLoading";

export default function Posts() {
  const [postsData, setPostsData] = useState(() => []);

  const [postsIsLoading, setPostsIsLoading] = useState(() => true);

  useEffect(() => {
    async function handleGetPostsRequest() {
      setPostsIsLoading(() => true);

      const requestData = {
        method: "GET",
        url: "public/posts/new-posts",
        isAdminServer: true,
      };

      try {
        const response = await sendRequest(requestData);

        if (!response.success) {
          return;
        }

        setPostsData(() => response.data);
      } catch (error) {
        window.alert(`Houve um erro no servidor ${error}`);
      } finally {
        setPostsIsLoading(() => false);
      }
    }

    handleGetPostsRequest();
  }, []);

  const postsElement = postsData.map((post) => {
    return (
      <Post
        title={post.title}
        content={post.content}
        imgUrl={post.thumbnail}
        key={post.id}
        id={post.id}
      />
    );
  });

  return (
    <section>
      <article className="p-4 md:pl-12 pb-10 md:pr-12 max-w-6xl m-auto flex flex-col gap-8 md:flex-row md:justify-between">
        {postsIsLoading ? (
          <div className="flex flex-col w-full items-center justify-center">
            <div className="relative p-10">
              <ComponentLoading customBg="bg-none" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full items-center gap-12">
            {postsElement.length > 0 ? (
              <div className="flex flex-col bg-yellow md:grid md:grid-cols-2 md:ml-10 gap-6">
                {postsElement}
              </div>
            ) : (
              <div>
                <p className="text-2xl text-center font-bold text-red-500">
                  Nenhum post encontrado.
                </p>
              </div>
            )}
          </div>
        )}
      </article>
    </section>
  );
}

function Post(props) {
  const domParser = new DOMParser();
  const contentDom = domParser.parseFromString(props.content, "text/html");

  const descriptionElements = contentDom.querySelectorAll("p");

  let description = "Nenhuma descrição encontrada.";
  let descriptionError = true;

  if (descriptionElements) {
    const descriptionsArray = Array.from(descriptionElements);

    for (const descriptionElement of descriptionsArray) {
      const descriptionText = descriptionElement.innerText;

      if (descriptionText.length >= 1) {
        descriptionError = false;
        description = descriptionText;
        break;
      }
    }
  }

  return (
    <article className="border-gray-100 first:col-span-2 bg-white border rounded-md shadow flex flex-col gap-2 first:lg:flex-row min-h-full">
      <div>
        <picture>
          <img
            src={props.imgUrl}
            alt="Post Cover"
            className="w-full max-h-96 object-cover lg:min-h-96 lg:min-w-[31rem] rounded-md"
          />
        </picture>
      </div>

      <article className="flex flex-col justify-center gap-6 p-4 sm:p-7 ">
        <h2 className="text-2xl font-bold text-communityprimary">
          {props.title}
        </h2>

        <p
          className={`text-base ${
            descriptionError
              ? "text-red-500 font-bold"
              : "text-textColor font-normal"
          } lg:max-w-[50ch] line-clamp-6`}
        >
          {description}
        </p>

        <Link
          to={`post/${props.id}`}
          title="Mais informações"
          className="flex items-center gap-2 text-communityprimary focus:text-tertiary hover:text-tertiary transition duration-200 text-base"
        >
          Mais informações <i className="icon-arrow-up-right2"></i>
        </Link>
      </article>
    </article>
  );
}
