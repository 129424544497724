import { useEffect, useState } from "react";
import { sendRequest } from "../../../util/util";
import ComponentLoading from "../../../components/ComponentLoading";
import { useOutletContext } from "react-router-dom";

export default function BusinessClients() {
  const [clientsAllowInfosRequests, setClientsAllowInfosRequests] = useState(
    () => []
  );

  const requiredInfos = JSON.parse(useOutletContext().requiredInfos);

  const allowInfos = requiredInfos.map((info, index) => {
    return <TableHead key={index} allowInfo={info} />;
  });

  const [isLoading, setIsLoading] = useState(() => true);

  const clientsAllowInfosRequestsRows = clientsAllowInfosRequests.map(
    (requestData, index) => {
      return (
        <ClientAllowInfosRequets
          key={index}
          clientData={requestData.clientData}
          requiredInfos={requestData.requiredInfos}
        />
      );
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    async function handleRequest() {
      const requestData = {
        method: "GET",
        url: "businesses/allow-infos",
      };

      setIsLoading(() => true);

      try {
        const response = await sendRequest(requestData);

        if (!response.success) {
          window.alert(
            "Houve um erro no servidor, tente novamente mais tarde."
          );
          return;
        }

        setClientsAllowInfosRequests(() => {
          return response.data;
        });
      } catch (error) {
        window.alert("Houve um erro no servidor, tente novamente mais tarde.");
      } finally {
        setIsLoading(() => false);
      }
    }

    handleRequest();
  }, []);

  return (
    <div className="pt-11">
      <article className="bg-customGray-profileGray p-10 flex flex-col gap-7">
        <h1 className="text-center text-primary font-bold text-lg sm:text-2xl lg:text-3xl">
          Clientes cadastrados
        </h1>

        <div className="bg-white relative flex p-5 pt-10 pb-10 flex-col gap-6 w-full rounded-2xl">
          {isLoading ? (
            <ComponentLoading />
          ) : clientsAllowInfosRequests.length >= 1 ? (
            <div>
              <div className="overflow-auto">
                <table className="text-left min-w-fit w-full">
                  <thead className="text-primary font-bold text-nowrap text-lg">
                    <tr>
                      <th className="p-2 pr-4 pl-4"></th>
                      {allowInfos}
                    </tr>
                  </thead>

                  <tbody className="text-nowrap text-gray-500 font-medium">
                    {clientsAllowInfosRequestsRows}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <h3 className="text-lg text-center sm:text-2xl font-bold text-red-500">
              Nenhuma cliente encontrada.
            </h3>
          )}
        </div>
      </article>
    </div>
  );
}

function TableHead(props) {
  return <th className="p-2 pr-4 pl-4">{props.allowInfo}</th>;
}

function TableData(props) {
  return <td className="p-4 pr-4 pl-4">{props.clientData[props.value]}</td>;
}

function ClientAllowInfosRequets(props) {
  const order = JSON.parse(props.requiredInfos).map((value, index) => {
    return (
      <TableData key={index} value={value} clientData={props.clientData} />
    );
  });

  return (
    <tr>
      <td className="p-4 pr-4 pl-4">
        <div>
          <picture className="w-24 block">
            <img
              className="w-full aspect-square rounded-full object-cover"
              src={props.clientData.img}
            />
          </picture>
        </div>
      </td>
      {order}
    </tr>
  );
}
