import { Outlet, Navigate } from "react-router-dom";

export default function LoginMiddleware() {
  let userData = localStorage.getItem("userData");

  if (userData) {
    userData = JSON.parse(userData);

    return (
      <Navigate
        to={userData.type === "client" ? "/perfil-cliente" : "/perfil-empresa"}
      />
    );
  }

  return <Outlet />;
}
