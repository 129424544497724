import { Link } from "react-router-dom";
import logo from "../assets/images/hero-image.png";

import logoWebp from "../assets/images/hero-image.webp";

export default function Footer() {
  return (
    <footer>
      <div className="pt-10 pb-10 lg:pt-28">
        <div className="p-3 flex flex-col gap-24 lg:flex-row lg:justify-between max-w-5xl m-auto">
          <article className="flex flex-col items-center gap-24 lg:justify-between">
            <div className="w-fit relative">
              <h2 className="text-customBlue-blue font-bold text-2xl z-10 uppercase">
                Contato
              </h2>

              <div className="w-[6.1rem] h-[4.2rem] border-[.5rem] rounded-[1.6rem] border-customBlue-whiteBlue absolute -top-1 -right-4 -z-10"></div>
            </div>

            <ul className="flex flex-col items-center gap-8">
              <li>
                <mark className="text-customBlue-blue font-bold text-base">
                  (11) 99898-2830
                </mark>
              </li>

              <li>
                <mark className="text-customBlue-blue font-bold text-base">
                  (11) 91438-9212
                </mark>
              </li>

              <li>
                <mark className="text-customBlue-blue font-bold text-base">
                  administrativo@sulkey.com.br
                </mark>
              </li>
            </ul>
          </article>

          <article className="flex flex-col items-center gap-24 lg:justify-between">
            <div className="w-fit relative">
              <h2 className="text-customBlue-blue font-bold text-2xl z-10 uppercase">
                Privacidade
              </h2>

              <div className="w-[6.1rem] h-[4.2rem] border-[.5rem] rounded-[1.6rem] border-customBlue-whiteBlue absolute -top-1 -right-4 -z-10"></div>
            </div>

            <nav>
              <ul className="flex flex-col items-center gap-8">
                <li>
                  <Link
                    to="/termos-de-uso"
                    className="text-customBlue-blue font-bold text-base hover:text-customBlue-whiteBlue regular-transition"
                  >
                    Termos de uso
                  </Link>
                </li>

                <li>
                  <Link
                    to="/politica-de-privacidade"
                    className="text-customBlue-blue font-bold text-base hover:text-customBlue-whiteBlue regular-transition"
                  >
                    Politica de privacidade
                  </Link>
                </li>
              </ul>
            </nav>
          </article>

          <article className="flex flex-col items-center gap-0 lg:justify-between">
            <div className="w-fit relative lg:mb-16">
              <h2 className="text-customBlue-blue font-bold text-2xl z-10 uppercase">
                MIDIA
              </h2>

              <div className="w-[6.1rem] h-[4.2rem] border-[.5rem] rounded-[1.6rem] border-customBlue-whiteBlue absolute -top-1 -right-11 -z-10"></div>
            </div>

            <nav className="mt-[9.2rem] lg:mt-0 lg:relative lg:top-[3.3rem]">
              <ul className="flex items-center justify-center gap-2">
                <li>
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    className="icon-facebook2 cursor-pointer inline-block text-white p-4 pt-2 pb-2 bg-black rounded-full text-lg hover:bg-customBlue-blue regular-transition"
                  ></a>
                </li>

                <li className="relative -top-12">
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    className="icon-instagram cursor-pointer inline-block text-white p-3 pt-2 pb-2 bg-black rounded-full text-lg hover:bg-customBlue-blue regular-transition"
                  ></a>
                </li>

                <li>
                  <a
                    href="https://youtube.com"
                    target="_blank"
                    className="icon-youtube cursor-pointer inline-block text-white p-3 pt-2 pb-2 bg-black rounded-full text-lg hover:bg-customBlue-blue regular-transition"
                  ></a>
                </li>
              </ul>
            </nav>

            <img
              src={logoWebp}
              className="w-full max-w-[16rem] h-[4.5rem] relative top-4 lg:top-10 lg:w-[18rem]"
              onError={(e) => {
                e.target.src = logo;
              }}
            />
          </article>
        </div>
      </div>
      <div className="p-3 bg-[#2D7AC7] mt-6">
        <p className="text-sm text-white font-normal text-center">
          © 2024 copyright Sulkey | Todos os direitos reservados.
        </p>
      </div>
    </footer>
  );
}
