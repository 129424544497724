export default function UpdateSuccess() {
  return (
    <div className="fixed top-0 left-0 w-full flex z-50 justify-center items-center">
      <div className="p-10 pt-7 pb-7 bg-white border-l border-r border-b border-gray-200 rounded-sm">
        <h2 className="text-lg text-green-500 font-bold text-center">
          Dados atualizados com sucesso.
        </h2>
      </div>
    </div>
  );
}
