export default function ComponentLoading(props) {
  return (
    <div
      className={`absolute flex rounded-2xl flex-col items-center justify-center top-0 bottom-0 left-0 right-0 ${
        props.customBg ? props.customBg : "bg-white"
      }`}
    >
      <div className="lds-ellipsis ">
        <div className="bg-primary"></div>
        <div className="bg-primary"></div>
        <div className="bg-primary"></div>
        <div className="bg-primary"></div>
      </div>
    </div>
  );
}
