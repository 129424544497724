import { useState } from "react";
import { sendRequest } from "../../util/util";
import { useNavigate } from "react-router-dom";

export default function LoginForm(props) {
  const formFor = props.formFor.toLowerCase();

  const isClient = formFor === "clientes";
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState(() => {
    return {
      credentials: {
        email: "",
        password: "",
        cnpj: "",
        type: isClient ? "client" : "business",
      },
      errorData: {
        error: false,
        msg: isClient ? "Email ou Senha Inválidos." : "CNPJ ou Senha Inválidos",
      },
    };
  });

  return (
    <div className="flex flex-col items-center gap-20">
      <div className="relative">
        <h1 className="uppercase text-4xl md:text-5xl text-primary font-normal">
          Login
        </h1>
        <div className="absolute w-36 h-9 bg-customGray-gray top-[.6rem] left-4 -z-10 custom-border-r md:w-44 md:left-5 md:top-[.8rem]"></div>
      </div>

      <div className="w-full">
        <article className="flex flex-col items-center gap-4">
          <h2 className="text-3xl text-primary font-bold lg:text-4xl text-center capitalize">
            {formFor}
          </h2>
          <p className="text-base text-primary font-bold text-center max-w-[40ch] lg:text-lg">
            Olá, que bom ter você de volta!
          </p>
        </article>

        <form
          onSubmit={(event) =>
            handleSubmit(event, loginForm, setLoginForm, navigate, isClient)
          }
          className="mt-8 flex flex-col  items-center gap-6 w-full"
          noValidate
        >
          <div className="w-full flex flex-col gap-2 items-center">
            <input
              onChange={(event) => handleCredentials(event, setLoginForm)}
              value={
                isClient
                  ? loginForm.credentials.email
                  : loginForm.credentials.cnpj
              }
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type={isClient ? `email` : "text"}
              name={isClient ? `email` : "cnpj"}
              placeholder={isClient ? `Email` : "CNPJ"}
            />
          </div>

          <div className="w-full flex flex-col gap-2 items-center">
            <input
              onChange={(event) => handleCredentials(event, setLoginForm)}
              value={loginForm.credentials.password}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="password"
              name="password"
              placeholder="Senha"
            />
          </div>

          <div className="w-full flex flex-col gap-4 items-center">
            <button
              className="p-2 hover:border-primary border text-base lg:text-lg cursor-pointer w-full font-bold text-primary bg-customBlue-whiteBlue rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="submit"
            >
              Fazer Login
            </button>

            {loginForm.errorData.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {loginForm.errorData.msg}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

function handleCredentials(event, setLoginForm) {
  const target = event.target;

  setLoginForm((prevLoginForm) => {
    return {
      ...prevLoginForm,
      credentials: {
        ...prevLoginForm.credentials,
        [target.name]: target.value,
      },
    };
  });
}

function handleSubmit(event, loginForm, setLoginForm, navigate, isClient) {
  event.preventDefault();

  handleRequest(loginForm.credentials, setLoginForm, navigate, isClient);
}

async function handleRequest(credentials, setLoginForm, navigate, isClient) {
  const requestData = {
    method: "POST",
    body: credentials,
    url: "login",
  };

  try {
    const response = await sendRequest(requestData);

    if (!response.success) {
      setLoginForm((prevLoginForm) => {
        return {
          ...prevLoginForm,
          errorData: {
            error: true,
            msg: isClient
              ? "Email ou Senha Inválidos."
              : "CNPJ ou Senha Inválidos",
          },
        };
      });
      return;
    }

    let userData = response.data.userData;

    localStorage.setItem("type", response.data.type);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("userData", JSON.stringify(userData));
    localStorage.setItem(
      "imagesPath",
      process.env.PUBLIC_URL + "/assets/images/"
    );

    if (response.data.type === "client") {
      return navigate("/perfil-cliente");
    } else if (response.data.type === "business") {
      const requiredInfos = JSON.parse(userData.requiredInfos);

      const newRequiredInfos = requiredInfos.map((info) => {
        if (info === "name") {
          return "Nome";
        }

        if (info === "phone") {
          return "Telefone";
        }

        if (info === "email") {
          return "Email";
        }

        if (info === "cpf") {
          return "CPF";
        }

        if (info === "birthday") {
          return "Nascimento";
        }

        if (info === "cep") {
          return "CEP";
        }
      });

      userData.requiredInfos = JSON.stringify(newRequiredInfos);

      localStorage.setItem("userData", JSON.stringify(userData));

      return navigate("/perfil-empresa");
    }
  } catch (error) {
    setLoginForm((prevLoginForm) => {
      return {
        ...prevLoginForm,
        errorData: {
          error: true,
          msg: "Houve um erro no servidor, tente novamente mais tarde.",
        },
      };
    });
  }
}
