import { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import { sendRequest } from "../../util/util";

export default function ClientSignUp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState(() => {
    return {
      data: {
        name: "",
        email: "",
        password: "",
        cep: "",
        cpf: "",
        birthday: "",
        phone: "",
        type: "client",
      },
      status: {
        success: false,
        errors: {
          name: {
            error: false,
            msg: "",
          },
          cpf: {
            error: false,
            msg: "",
          },
          birthday: {
            error: false,
            msg: "",
          },
          email: {
            error: false,
            msg: "",
          },
          phone: {
            error: false,
            msg: "",
          },
          cep: {
            error: false,
            msg: "",
          },
          password: {
            error: false,
            msg: "",
          },
        },
      },
    };
  });

  const [isLoading, setIsLoading] = useState(() => false);

  return (
    <div>
      <article className="bg-customGray-profileGray p-3 pt-14 pb-10 flex flex-col gap-10 sm:p-6 sm:pb-10 items-center lg:p-10 lg:pb-14 lg:pt-14">
        <div className="flex flex-col gap-6">
          <h1 className="text-3xl text-center font-bold text-primary sm:text-4xl lg:text-5xl">
            Cadastro
          </h1>

          <p className="text-primary text-base sm:text-lg text-center max-w-[50ch] font-medium">
            Garanta já seu cadastro e fique por dentro de todas as atualizações,
            ajudando esse projeto a crescer.
          </p>
        </div>

        <form
          onSubmit={(event) =>
            handleSubmit(event, formData, setFormData, setIsLoading)
          }
          noValidate
          className="mt-8 flex flex-col  items-center gap-6 w-full max-w-[19rem]"
        >
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="name" className="text-primary font-bold text-lg">
              Nome completo:
            </label>
            <input
              id="name"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.name}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="name"
              placeholder="Nome"
            />

            {formData.status.errors.name.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.name.msg}
              </p>
            )}
          </div>
          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="cpf" className="text-primary font-bold text-lg">
              CPF:
            </label>
            <input
              id="cpf"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.cpf}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="cpf"
              placeholder="CPF"
            />

            {formData.status.errors.cpf.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.cpf.msg}
              </p>
            )}
          </div>
          <div className="w-full flex flex-col gap-2 ">
            <label
              htmlFor="birthday"
              className="text-primary font-bold text-lg"
            >
              Nascimento:
            </label>
            <input
              id="birthday"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.birthday}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="date"
              name="birthday"
            />

            {formData.status.errors.birthday.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.birthday.msg}
              </p>
            )}
          </div>
          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="email" className="text-primary font-bold text-lg">
              Email:
            </label>
            <input
              id="email"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.email}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="email"
              name="email"
              placeholder="Email"
            />

            {formData.status.errors.email.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.email.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="phone" className="text-primary font-bold text-lg">
              Telefone:
            </label>
            <input
              id="phone"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.phone}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="phone"
              placeholder="Telefone"
            />

            {formData.status.errors.phone.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.phone.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label htmlFor="cep" className="text-primary font-bold text-lg">
              CEP:
            </label>
            <input
              id="cep"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.cep}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="text"
              name="cep"
              placeholder="CEP"
            />

            {formData.status.errors.cep.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.cep.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-2 ">
            <label
              htmlFor="password"
              className="text-primary font-bold text-lg"
            >
              Senha:
            </label>
            <input
              id="password"
              onChange={(event) => handleOnChange(event, setFormData)}
              value={formData.data.password}
              className="p-2 pr-4 pl-4 focus:border-primary border text-base text-primary lg:text-lg w-full placeholder:text-primary font-bold bg-customGray-inputGray rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="password"
              name="password"
              placeholder="Sua senha"
            />

            {formData.status.errors.password.error && (
              <p className="text-red-500 font-medium text-center text-base lg:text-lg">
                {formData.status.errors.password.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col gap-4 mt-4">
            <button
              className="p-2 hover:border-primary border text-base lg:text-lg cursor-pointer w-full font-bold text-primary bg-customBlue-whiteBlue rounded-3xl regular-shadow max-w-[17rem] lg:max-w-[19rem] text-center "
              type="submit"
            >
              Cadastrar-se
            </button>

            {formData.status.success && (
              <p className="text-green-500 text-center font-medium text-base lg:text-lg">
                Dados salvos com sucesso. Confirme seu email para ativar seu
                perfil.
              </p>
            )}
          </div>
        </form>
      </article>
      {isLoading && <Loading />}
    </div>
  );
}

function handleSubmit(event, formData, setFormData, setIsLoading) {
  event.preventDefault();

  const method = "POST";

  setIsLoading(() => true);

  const requestData = {
    method,
    body: formData.data,
    url: "register",
  };

  handleRequest(requestData, setIsLoading, setFormData);
}

async function handleRequest(requestData, setIsLoading, setFormData) {
  try {
    const response = await sendRequest(requestData);

    if (!response.success) {
      const errors = response.errors;

      setFormData((prevFormData) => {
        const newFormData = { ...prevFormData };
        for (let error in prevFormData.status.errors) {
          if (errors[error]) {
            newFormData.status.errors[error].error = true;
            newFormData.status.errors[error].msg = errors[error];
          } else {
            newFormData.status.errors[error].error = false;
          }
        }

        newFormData.status.success = false;

        return newFormData;
      });

      return;
    }

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      for (let error in prevFormData.status.errors) {
        newFormData.status.errors[error].error = false;
      }

      newFormData.status.success = true;

      return newFormData;
    });

    setTimeout(() => {
      setFormData((prevFormData) => {
        return {
          data: {
            name: "",
            email: "",
            password: "",
            cep: "",
            cpf: "",
            birthday: "",
            phone: "",
            type: "client",
          },
          status: { ...prevFormData.status },
        };
      });
    }, 4000);
  } catch (error) {
    window.alert(
      "Houve um error no servidor, por favor, tente novamente mais tarde."
    );
  } finally {
    setIsLoading(() => false);
  }
}

function handleOnChange(event, setFormData) {
  const target = event.target;

  const targetName = target.name;
  const targetValue = target.value;

  setFormData((prevFormData) => {
    return {
      ...prevFormData,
      data: { ...prevFormData.data, [targetName]: targetValue },
    };
  });
}
