import { Outlet, Navigate, useLocation } from "react-router-dom";

export default function Auth() {
  const currentLocation = useLocation().pathname;

  let userData = localStorage.getItem("userData");
  const userToken = localStorage.getItem("token");

  if (!userData || !userToken) {
    return <Navigate to="./cliente-login" />;
  }

  userData = JSON.parse(userData);

  const isClientPage = currentLocation.startsWith("/perfil-cliente");
  const isBusinessPage = currentLocation.startsWith("/perfil-empresa");

  if (isBusinessPage && userData.type === "client") {
    return <Navigate to="/perfil-cliente" />;
  } else if (isClientPage && userData.type === "business") {
    return <Navigate to="/perfil-empresa" />;
  }

  return <Outlet />;
}
