import { useEffect } from "react";

export default function PravicyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <article className="p-3 py-16 flex flex-col gap-10 lg:gap-16 m-auto max-w-6xl">
        <h1 className="text-2xl md:text-4xl text-center text-primary font-bold">
          POLÍTICA DE PRIVACIDADE
          <br /> SULKEY
        </h1>

        <section className="app-policy">
          <article>
            <h2>SEÇÃO 1 ~ INFORMAÇÕES GERAIS</h2>

            <ol>
              <li>
                <p>
                  <mark>1.1.</mark> A presente Política de Privacidade contém
                  informações sobre coleta, uso, armazenamento, tratamento e
                  proteção dos dados pessoais dos <mark>USUÁRIOS</mark> e
                  visitantes do site, com a finalidade de demonstrar absoluta
                  transparência quanto ao assunto e esclarecer a todos
                  interessados sobre os tipos de dados que são coletados, os
                  motivos da coleta e a forma como os <mark>USUÁRIOS</mark>{" "}
                  podem gerenciar ou excluir as suas informações pessoais.
                </p>
              </li>
              <li>
                <p>
                  <mark>1.2.</mark> O presente documento foi elaborado em
                  conformidade com a Lei Geral de Proteção de Dados Pessoais
                  (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) (e
                  o Regulamento da UE n. 2016/6790). Ainda, o documento poderá
                  ser atualizado em decorrência de eventual atualização
                  normativa, razão pela qual se convida o <mark>USUÁRIO</mark> a
                  consultar periodicamente esta seção.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>
              SEÇÃO 2 ~ COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E QUAIS
              DADOS SÃO COLETADOS?
            </h2>

            <ol>
              <li>
                <p>
                  <mark>2.1.</mark> Os dados pessoais do <mark>USUÁRIO</mark> e
                  visitante são recolhidos pela plataforma da quando o{" "}
                  <mark>USUÁRIO</mark> realiza seu cadastro na plataforma
                  Sulkey.
                </p>
              </li>
              <li>
                <ol>
                  <li>
                    <p>
                      <mark>2.2.</mark> Os dados pessoais coletados serão:
                    </p>
                  </li>
                  <li>
                    <p>{"a) Nome;"}</p>
                  </li>
                  <li>
                    <p>{"b) E-mail;"}</p>
                  </li>
                  <li>
                    <p>{"c) Número de Telefone;"}</p>
                  </li>
                  <li>
                    <p>{"d) CPF;"}</p>
                  </li>
                  <li>
                    <p>{"e) Endereço; e"}</p>
                  </li>
                  <li>
                    <p>{"f) Data de Nascimento."}</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <mark>2.3.</mark> A partir deles, podemos identificar o{" "}
                  <mark>USUÁRIO</mark> e o visitante, além de garantir uma maior
                  segurança e bem-estar às suas necessidades. Ficam cientes os{" "}
                  <mark>USUÁRIOS</mark> e visitantes de que seu perfil na
                  plataforma estará acessível a todos demais{" "}
                  <mark>USUÁRIOS</mark> e visitantes da plataforma.
                </p>
              </li>
              <li>
                <p>
                  <mark>2.4.</mark> Quando um <mark>USUÁRIO</mark> e visitante
                  acessa o aplicativo Sulkey as informações sobre interação e
                  acesso são coletadas pela empresa para garantir uma melhor
                  experiência ao <mark>USUÁRIO</mark> e visitante. Estes dados
                  podem tratar sobre as palavras-chaves utilizadas em uma busca,
                  o compartilhamento de um documento específico, comentários,
                  visualizações de páginas, perfis, a URL de onde o{" "}
                  <mark>USUÁRIO</mark> e visitante provêm, o navegador que
                  utilizam e seus IPs de acesso, dentre outras que poderão ser
                  armazenadas e retidas.
                </p>
              </li>
              <li>
                <p>
                  <mark>2.5.</mark> Os <mark>USUÁRIOS</mark> fornecerão seu
                  consentimento por meio dos botões “Aceito” e “Autorizo”
                  durante o compartilhamento de informações, seja para a Sulkey
                  ou terceiros.
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    2.6. Especificação sobre o Uso e Compartilhamento de Dados:
                  </mark>{" "}
                  <br />
                  Entendemos a importância da transparência e clareza no que diz
                  respeito ao uso e compartilhamento dos dados pessoais dos
                  nossos usuários. Como parte do nosso compromisso em proteger a
                  sua privacidade, nos comprometemos a fornecer informações
                  detalhadas sobre como os seus dados serão utilizados e
                  compartilhados.
                </p>
              </li>
              <li>
                <p>
                  <mark>2.6.1.</mark> Ao fornecer os seus dados pessoais, você
                  concorda explicitamente que os mesmos poderão ser utilizados
                  para as seguintes finalidades:
                </p>
                <ul>
                  <li>
                    <p>Personalização da sua experiência na plataforma;</p>
                  </li>
                  <li>
                    <p>Melhoria dos nossos serviços e funcionalidades;</p>
                  </li>
                  <li>
                    <p>
                      Comunicação de atualizações, ofertas especiais e
                      informações relevantes sobre os nossos produtos ou
                      serviços, caso tenha optado por receber tais comunicações;
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                <p>
                  <mark>
                    2.6.2. Cumprimento de obrigações legais ou regulatórias.
                  </mark>{" "}
                  <br />
                  Além disso, podemos compartilhar seus dados pessoais com
                  terceiros apenas nas seguintes circunstâncias:
                </p>

                <ul>
                  <li>
                    <p>
                      Quando necessário para a prestação dos serviços
                      contratados por você;
                    </p>
                  </li>
                  <li>
                    <p>Com o seu consentimento expresso;</p>
                  </li>
                </ul>
              </li>

              <li>
                <p>
                  <mark>
                    2.6.3. Para cumprimento de obrigações legais ou judiciais.
                  </mark>{" "}
                  <br />
                  Nos comprometemos a garantir que qualquer compartilhamento de
                  dados com terceiros seja realizado de forma transparente e com
                  base em acordos de confidencialidade e segurança de dados. Em
                  nenhum momento venderemos ou alugaremos os seus dados pessoais
                  a terceiros para fins de marketing sem o seu consentimento
                  explícito.
                </p>
              </li>
              <li>
                <p>
                  <mark>2.6.4</mark> Se tiver alguma dúvida ou preocupação sobre
                  o uso ou compartilhamento dos seus dados pessoais, por favor,
                  entre em contato conosco através dos canais de comunicação
                  disponibilizados nesta política.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>SEÇÃO 3 ~ FINALIDADE DO ARMAZENAMENTO DE DADOS</h2>

            <ol>
              <li>
                <p>
                  <mark>3.1.</mark> Os dados pessoais do <mark>USUÁRIO</mark> e
                  do visitante coletados e armazenados pela plataforma Sulkey
                  tem por finalidade a criação de cadastro do{" "}
                  <mark>USUÁRIO</mark> em uma plataforma de terceiros, conforme
                  autorizado pelo <mark>USUÁRIO</mark>
                </p>
              </li>
              <li>
                <p>
                  <mark>3.2.</mark> O tratamento de dados pessoais para
                  finalidades não previstas nesta Política de Privacidade
                  somente ocorrerá mediante comunicação prévia ao{" "}
                  <mark>USUÁRIO</mark>, de modo que os direitos e obrigações
                  aqui previstos permanecem aplicáveis.
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    3.3. Especificação sobre Plataformas de Terceiros e
                    Compartilhamento de Dados:
                  </mark>{" "}
                  <br />
                  Reconhecemos a importância de fornecer informações precisas e
                  detalhadas sobre o compartilhamento de dados com plataformas
                  de terceiros. Compreendemos que a transparência nesse processo
                  é fundamental para garantir a confiança e a privacidade dos
                  nossos usuários. Portanto, nos comprometemos a esclarecer de
                  forma explícita quais plataformas de terceiros terão acesso
                  aos seus dados pessoais e como esses dados serão
                  compartilhados.
                </p>
              </li>
              <li>
                <p>
                  <mark>3.3.1. </mark> Ao utilizar nossos serviços, você
                  concorda que seus dados pessoais podem ser compartilhados com
                  as seguintes categorias de plataformas de terceiros:
                </p>

                <ul>
                  <li>
                    <p>
                      Provedores de serviços: Podemos compartilhar seus dados
                      pessoais com provedores de serviços terceirizados que nos
                      auxiliam na prestação e melhoria dos nossos serviços, como
                      processamento de pagamentos, hospedagem de dados, análise
                      de dados e suporte técnico.
                    </p>
                  </li>
                  <li>
                    <p>
                      Parceiros comerciais: Em alguns casos, podemos
                      compartilhar seus dados pessoais com parceiros comerciais
                      estratégicos para oferecer produtos ou serviços conjuntos,
                      desde que você tenha consentido expressamente para tal
                      compartilhamento.
                    </p>
                  </li>
                  <li>
                    <p>
                      Redes sociais e plataformas de publicidade: Se você optar
                      por interagir com nossos serviços por meio de redes
                      sociais ou clicar em anúncios veiculados em nossas
                      plataformas, seus dados pessoais podem ser compartilhados
                      com essas redes sociais e plataformas de publicidade de
                      acordo com suas políticas de privacidade.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <mark>3.3.2.</mark> Asseguramos que qualquer compartilhamento
                  de dados com plataformas de terceiros será realizado de acordo
                  com as disposições legais aplicáveis e em conformidade com os
                  mais altos padrões de segurança e privacidade de dados.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>SEÇÃO 4 ~ SOBRE O ARMAZENAMENTO DOS DADOS</h2>

            <ol>
              <li>
                <p>
                  <mark>4.1.</mark> As informações coletadas são armazenadas de
                  forma segura em um banco de dados.
                </p>
              </li>
              <li>
                <p>
                  <mark>4.2.</mark>
                  Os dados pessoais do <mark>USUÁRIO</mark> e visitante são
                  armazenados pela plataforma durante o período necessário para
                  a prestação do serviço ou o cumprimento das finalidades
                  previstas no presente documento, conforme o disposto no inciso
                  I do artigo 15 da Lei 13.709/18.
                </p>
              </li>
              <li>
                <p>
                  <mark>4.3.</mark> Os dados podem ser removidos ou anonimizados
                  a pedido do <mark>USUÁRIO</mark> , excetuando os casos em que
                  a lei oferecer outro tratamento
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    4.4. Detalhes sobre a Exclusão ou Anonimização de Dados:
                  </mark>{" "}
                  <br />
                  Entendemos a importância da transparência no processo de
                  exclusão ou anonimização de dados pessoais após o término do
                  período de retenção. Reconhecemos que fornecer informações
                  detalhadas sobre esse processo é essencial para garantir a
                  privacidade e a segurança dos nossos usuários. Portanto, nos
                  comprometemos a fornecer esclarecimentos adicionais sobre como
                  os dados serão excluídos ou anonimizados após o término do
                  período de retenção.
                </p>
              </li>
              <li>
                <p>
                  <mark>4.4.1.</mark> Após o término do período de retenção dos
                  seus dados pessoais, nos comprometemos a realizar uma das
                  seguintes ações:
                </p>

                <ul>
                  <li>
                    <p>
                      Exclusão permanente: Os seus dados pessoais serão
                      excluídos de forma permanente dos nossos sistemas e bancos
                      de dados, garantindo que não sejam mais acessíveis ou
                      recuperáveis de forma alguma.
                    </p>
                  </li>
                  <li>
                    <p>
                      Anonimização: Caso a exclusão permanente não seja viável
                      por razões legais ou operacionais, nos comprometemos a
                      anonimizar os seus dados pessoais de forma irreversível,
                      removendo todas as informações que possam identificar
                      diretamente você como indivíduo.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <mark>4.4.2.</mark> Asseguramos que tanto o processo de
                  exclusão quanto o de anonimização dos seus dados pessoais
                  serão realizados de acordo com as disposições legais
                  aplicáveis e seguindo os mais rigorosos padrões de segurança e
                  privacidade de dados.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>SEÇÃO 5 ~ SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</h2>

            <ol>
              <li>
                <p>
                  <mark>5.1.</mark> A plataforma se compromete a aplicar as
                  medidas técnicas e organizativas aptas a proteger os dados
                  pessoais de acessos não autorizados e de situações de
                  destruição, perda, alteração, comunicação ou difusão de tais
                  dados.
                </p>
              </li>
              <li>
                <p>
                  <mark>5.2.</mark> Os dados pessoais armazenados são tratados
                  com confidencialidade, dentro dos limites legais. No entanto,
                  podemos divulgar suas informações pessoais caso sejamos
                  obrigados pela lei para fazê-lo ou se você violar nossos
                  Termos de Serviço.
                </p>
              </li>
              <li>
                <p>
                  <mark>5.3. </mark> A plataforma não se exime de
                  responsabilidade por culpa exclusiva de terceiros, como em
                  caso de ataque de hackers ou crackers, ou culpa exclusiva do{" "}
                  <mark>USUÁRIO</mark>, como no caso em que ele mesmo transfere
                  seus dados a terceiros. O site se compromete a comunicar o{" "}
                  <mark>USUÁRIO</mark> em caso de alguma violação de segurança
                  dos seus dados pessoais.
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    5.4 Proteção de Dados e Notificação em Caso de Violação:
                  </mark>{" "}
                  <br />
                  Reconhecemos a importância de fornecer informações detalhadas
                  sobre as medidas de segurança adotadas para proteger os dados
                  contra acessos não autorizados, bem como sobre nossa política
                  de notificação em caso de violação de dados. Compreendemos que
                  a transparência nesse aspecto é crucial para garantir a
                  confiança e a segurança dos nossos usuários. Portanto, nos
                  comprometemos a incluir mais informações sobre esses temas na
                  presente política.
                </p>
              </li>
              <li>
                <p>
                  <mark>5.4.1. Proteção contra Acessos Não Autorizados:</mark>{" "}
                  <br />
                  Asseguramos que implementamos medidas técnicas,
                  administrativas e organizacionais adequadas para proteger os
                  seus dados pessoais contra acessos não autorizados, uso
                  indevido, divulgação, alteração ou destruição. Essas medidas
                  incluem, mas não se limitam a:
                </p>

                <ul>
                  <li>
                    <p>
                      Criptografia de dados: Utilizamos tecnologias de
                      criptografia para proteger a transmissão e o armazenamento
                      dos seus dados pessoais.
                    </p>
                  </li>

                  <li>
                    <p>
                      Controles de acesso: Restringimos o acesso aos seus dados
                      apenas a colaboradores autorizados que necessitam de tais
                      informações para desempenhar suas funções.
                    </p>
                  </li>

                  <li>
                    <p>
                      Monitoramento contínuo: Realizamos monitoramento contínuo
                      dos nossos sistemas e redes para detectar e responder
                      rapidamente a qualquer atividade suspeita.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <mark>
                    5.4.2. Política de Notificação em Caso de Violação de Dados:
                  </mark>{" "}
                  <br />
                  Em caso de violação de dados que possa comprometer a segurança
                  ou a privacidade dos seus dados pessoais, nos comprometemos a
                  notificá-lo imediatamente após tomarmos conhecimento do
                  incidente. A notificação será realizada de forma clara e
                  transparente e incluirá informações sobre a natureza da
                  violação, os dados afetados, as medidas que estamos tomando
                  para remediar a situação e as recomendações que você pode
                  seguir para proteger sua segurança.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>SEÇÃO 6 ~COMPARTILHAMENTO DE DADOS</h2>

            <ol>
              <li>
                <p>
                  <mark>6.1.</mark> Nenhuma informação é manejada sem
                  autorização do USUÁRIO via e-mail ou App.
                </p>
              </li>
              <li>
                <p>
                  <mark>6.2.</mark> Todas as informações compartilhadas com
                  terceiros exigem autorização explícita do{" "}
                  <mark> USUÁRIO</mark>. Durante o processo de autorização,
                  todas as informações a serem compartilhadas serão claramente
                  comunicadas.
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    6.3. Exemplos de Compartilhamento de Dados e Processo de
                    Consentimento:
                  </mark>{" "}
                  <br />
                  Entendemos a importância de fornecer exemplos específicos de
                  situações em que os dados podem ser compartilhados com
                  terceiros, bem como esclarecer como o consentimento será
                  obtido para esse compartilhamento. Reconhecemos que a
                  transparência nessas situações é fundamental para garantir a
                  confiança e a privacidade dos nossos usuários. Portanto, nos
                  comprometemos a incluir mais informações sobre esses temas na
                  presente política.
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    6.3.1. Exemplos de Situações de Compartilhamento de Dados:
                  </mark>{" "}
                </p>

                <ul>
                  <li>
                    <p>
                      Compartilhamento com provedores de serviços: Seus dados
                      pessoais podem ser compartilhados com provedores de
                      serviços terceirizados que nos auxiliam na prestação e
                      melhoria dos nossos serviços, tais como processamento de
                      pagamentos, hospedagem de dados, análise de dados e
                      suporte técnico.
                    </p>
                  </li>
                  <li>
                    <p>
                      Compartilhamento com parceiros comerciais: Em algumas
                      circunstâncias, podemos compartilhar seus dados pessoais
                      com parceiros comerciais estratégicos para oferecer
                      produtos ou serviços conjuntos. Nesses casos, o
                      compartilhamento só será realizado com o seu consentimento
                      expresso.
                    </p>
                  </li>
                  <li>
                    <p>
                      Compartilhamento com redes sociais e plataformas de
                      publicidade: Se você optar por interagir com nossos
                      serviços por meio de redes sociais ou clicar em anúncios
                      veiculados em nossas plataformas, seus dados pessoais
                      podem ser compartilhados com essas redes sociais e
                      plataformas de publicidade de acordo com suas políticas de
                      privacidade.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <mark>6.3.2. Processo de Consentimento:</mark> <br />
                  Asseguramos que obteremos o seu consentimento explícito antes
                  de compartilhar seus dados pessoais com terceiros em qualquer
                  uma das situações mencionadas acima. O consentimento será
                  obtido de forma clara e transparente, e você terá a opção de
                  aceitar ou recusar o compartilhamento de dados. Além disso,
                  garantiremos que você seja informado sobre os detalhes
                  específicos do compartilhamento, incluindo os tipos de dados
                  compartilhados, os terceiros envolvidos e as finalidades do
                  compartilhamento.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>SEÇÃO 7 ~ CONSENTIMENTO </h2>

            <ol>
              <li>
                <p>
                  <mark>7.1.</mark> Ao utilizar os serviços e fornecer as
                  informações pessoais na plataforma, o <mark>USUÁRIO</mark>{" "}
                  está consentindo com a presente Política de Privacidade.
                </p>
              </li>
              <li>
                <p>
                  <mark>7.2.</mark>Os <mark>USUÁRIOS</mark> fornecerão seu
                  consentimento por meio dos botões “Aceito” e “Autorizo”
                  durante o compartilhamento de informações, seja para a Sulkey
                  ou terceiros.
                </p>
              </li>
              <li>
                <p>
                  <mark>7.3</mark> O <mark>USUÁRIO</mark>, ao cadastrar-se,
                  manifesta conhecer e pode exercitar seus direitos de cancelar
                  seu cadastro, acessar e atualizar seus dados pessoais e
                  garante a veracidade das informações por ele disponibilizadas.
                </p>
              </li>
              <li>
                <p>
                  <mark>7.4.</mark> O <mark>USUÁRIO</mark> tem direito de
                  retirar o seu consentimento a qualquer tempo, para tanto deve
                  entrar em contato através do e-mail _______ ou por correio
                  enviado ao seguinte endereço: ________
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    7.5. Retirada de Consentimento e Impacto no Uso dos
                    Serviços:
                  </mark>
                  <br />
                  Reconhecemos a importância de fornecer informações claras e
                  transparentes sobre como os usuários podem retirar seu
                  consentimento e como isso afetará o uso dos serviços.
                  Compreendemos que a transparência nesse processo é fundamental
                  para garantir a autonomia e a privacidade dos nossos usuários.
                  Portanto, nos comprometemos a incluir mais informações sobre
                  esses temas na presente política.
                </p>
              </li>
              <li>
                <p>
                  <mark>7.5.1. Retirada de Consentimento:</mark> <br />
                  Você tem o direito de retirar o seu consentimento para o
                  processamento dos seus dados pessoais a qualquer momento,
                  desde que esse processamento seja baseado no consentimento.
                  Para retirar o seu consentimento, basta entrar em contato
                  conosco através dos canais de comunicação disponibilizados
                  nesta política, informando sua decisão de retirar o
                  consentimento. Após recebermos sua solicitação de retirada de
                  consentimento, iremos interromper o processamento dos seus
                  dados pessoais para as finalidades consentidas, conforme
                  aplicável.
                </p>
              </li>
              <li>
                <p>
                  <mark>7.5.2. Impacto no Uso dos Serviços:</mark> <br />É
                  importante ressaltar que a retirada do seu consentimento pode
                  afetar o uso dos nossos serviços de algumas formas, dependendo
                  das circunstâncias específicas. Por exemplo:
                </p>

                <ul>
                  <li>
                    <p>
                      Se você retirar o consentimento para o processamento dos
                      seus dados pessoais necessários para a prestação dos
                      nossos serviços, podemos não ser capazes de fornecer os
                      serviços solicitados.
                    </p>
                  </li>
                  <li>
                    <p>
                      Se você retirar o consentimento para o processamento dos
                      seus dados pessoais para fins de marketing ou comunicação,
                      isso pode resultar na interrupção do recebimento de
                      comunicações promocionais ou atualizações sobre nossos
                      produtos ou serviços.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <mark>7.5.3. </mark> Asseguramos que faremos todos os esforços
                  razoáveis para minimizar o impacto da retirada do
                  consentimento no uso dos nossos serviços e para garantir que
                  você possa exercer seus direitos de forma eficaz, sem
                  obstáculos injustificados.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>SEÇÃO 8 ~ COOKIES OU DADOS DE NAVEGAÇÃO</h2>

            <ol>
              <li>
                <p>
                  <mark>8.1.</mark> Os cookies referem-se a arquivos de texto
                  enviados pela plataforma ao computador do <mark>USUÁRIO</mark>{" "}
                  e visitante e que nele ficam armazenados, com informações
                  relacionadas à navegação no site. Tais informações são
                  relacionadas aos dados de acesso como local e horário de
                  acesso e são armazenadas pelo navegador do{" "}
                  <mark>USUÁRIO</mark> e visitante para que o servidor da
                  plataforma possa lê-las posteriormente a fim de personalizar
                  os serviços da plataforma.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.2.</mark> Até o momento, o Sulkey não utiliza cookies
                  ou web beacons.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3. Atualização da Política de Cookies:</mark>
                  <br />
                  Entendemos a importância de manter nossos usuários informados
                  sobre qualquer mudança na política de cookies, garantindo
                  assim transparência e conformidade contínuas com as
                  regulamentações de privacidade. Reconhecemos que a
                  transparência nesse aspecto é fundamental para garantir a
                  confiança e a privacidade dos nossos usuários. Portanto, nos
                  comprometemos a incluir uma cláusula específica sobre a
                  atualização da política de cookies na presente política.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3.1. Atualização da Política de Cookies:</mark>
                  <br />
                  Caso ocorra qualquer alteração na nossa política de cookies no
                  futuro, nos comprometemos a atualizar esta política de
                  privacidade para refletir tais mudanças. As atualizações serão
                  publicadas nesta política de privacidade e serão acompanhadas
                  por uma data de revisão para que os usuários possam
                  identificar facilmente as alterações feitas.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3.2. Notificação de Alterações:</mark>
                  <br />
                  Além disso, nos comprometemos a comunicar qualquer alteração
                  significativa na política de cookies aos nossos usuários por
                  meio de comunicações diretas, quando aplicável, ou por meio de
                  avisos destacados em nosso site. Isso garantirá que nossos
                  usuários estejam plenamente cientes de quaisquer mudanças na
                  política de cookies e possam revisar e ajustar suas
                  preferências de consentimento, se necessário.
                </p>
              </li>
              <li>
                <p>
                  <mark>8.3.3.</mark> Asseguramos que quaisquer alterações na
                  política de cookies serão realizadas em conformidade com as
                  leis e regulamentações aplicáveis de proteção de dados e
                  privacidade.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>SEÇÃO 9 ~ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</h2>

            <ol>
              <li>
                <p>
                  <mark>9.1.</mark> Reservamos o direito de modificar essa
                  Política de Privacidade a qualquer momento.
                </p>
              </li>
              <li>
                <p>
                  <mark>9.2.</mark> Alterações na política de privacidade serão
                  comunicadas aos <mark>USUÁRIOS</mark> por e-mail, App e podem
                  ser acessadas no site.
                </p>
              </li>
              <li>
                <p>
                  <mark>9.3.</mark> As alterações e esclarecimentos vão surtir
                  efeito imediatamente após sua publicação na plataforma. Quando
                  realizadas alterações os USUÁRIOS serão notificados. Ao
                  utilizar o serviço ou fornecer informações pessoais após
                  eventuais modificações, o USUÁRIO e visitante demonstra sua
                  concordância com as novas normas.
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    9.4. Descrição Detalhada de Alterações na Política de
                    Privacidade:
                  </mark>{" "}
                  <br />
                  Entendemos a importância de fornecer uma descrição detalhada
                  das alterações específicas feitas na política de privacidade,
                  bem como esclarecer como essas mudanças afetarão os usuários.
                  Reconhecemos que a transparência nesse processo é crucial para
                  garantir a confiança e a privacidade dos nossos usuários.
                  Portanto, nos comprometemos a incluir uma cláusula específica
                  sobre este assunto na presente política.
                </p>
              </li>
              <li>
                <p>
                  <mark>9.4.1. Descrição Detalhada de Alterações:</mark> <br />
                  Em caso de qualquer alteração na nossa política de
                  privacidade, nos comprometemos a fornecer uma descrição
                  detalhada das mudanças específicas realizadas. Esta descrição
                  incluirá informações sobre os aspectos da política de
                  privacidade que foram alterados, os motivos para tais mudanças
                  e como essas mudanças afetarão os usuários, incluindo qualquer
                  impacto potencial sobre os direitos dos usuários ou sobre o
                  uso dos nossos serviços.
                </p>
              </li>
              <li>
                <p>
                  <mark>9.4.2. Comunicação de Alterações:</mark> <br />
                  Além disso, nos comprometemos a comunicar as alterações na
                  política de privacidade aos nossos usuários de forma clara e
                  transparente. As alterações serão destacadas em nossa
                  plataforma e os usuários serão notificados por meio de
                  comunicações diretas, quando aplicável, ou por meio de avisos
                  destacados em nosso site.
                </p>
              </li>
              <li>
                <p>
                  <mark>9.4.3. Permissão para Aceitação:</mark> <br />
                  Para que os usuários possam tomar uma decisão informada sobre
                  a aceitação das alterações na política de privacidade, eles
                  serão solicitados a revisar e aceitar explicitamente as
                  mudanças antes que estas entrem em vigor. Os usuários terão a
                  opção de aceitar as alterações ou de recusá-las, se assim
                  desejarem.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>SEÇÃO 10 ~ CONTATO E DÚVIDAS</h2>

            <ol>
              <li>
                <p>
                  <mark>10.1.</mark> Os <mark>USUÁRIOS</mark> podem entrar em
                  contato por WhatsApp, telefone, e-mail, tickets na plataforma,
                  comentários no blog de atualizações e redes sociais.
                </p>
              </li>
              <li>
                <p>
                  <mark>
                    10.2. Tempo de Resposta para Consultas de Privacidade e
                    Segurança:
                  </mark>{" "}
                  <br />
                  Reconhecemos a importância de fornecer informações claras
                  sobre o tempo de resposta esperado para consultas relacionadas
                  à privacidade e segurança dos nossos usuários. Compreendemos
                  que a transparência nesse aspecto é fundamental para garantir
                  a confiança e a satisfação dos nossos usuários. Portanto, nos
                  comprometemos a incluir uma cláusula específica sobre este
                  assunto na presente política.
                </p>
              </li>
              <li>
                <p>
                  <mark>10.2.1. Tempo de Resposta Esperado:</mark> <br />
                  Nos comprometemos a responder prontamente a todas as consultas
                  relacionadas à privacidade e segurança dos nossos usuários. O
                  tempo de resposta esperado para tais consultas pode variar
                  dependendo da complexidade da solicitação, mas faremos o
                  possível para responder dentro de um prazo razoável e de
                  acordo com os requisitos legais aplicáveis.
                </p>
              </li>
              <li>
                <p>
                  <mark>10.2.2. Canais de Comunicação:</mark> <br />
                  Os usuários podem entrar em contato conosco para fazer
                  consultas relacionadas à privacidade e segurança através dos
                  canais de comunicação disponibilizados nesta política, como
                  e-mail, formulários de contato ou telefone. Faremos todos os
                  esforços para garantir que as consultas sejam tratadas com a
                  devida atenção e prioridade.
                </p>
              </li>
              <li>
                <p>
                  <mark>10.2.3. Transparência e Comunicação:</mark> <br />
                  Além de fornecer um tempo de resposta esperado, nos
                  comprometemos a manter os usuários informados sobre o status
                  de suas consultas, caso haja a necessidade de um prazo maior
                  para resposta ou se houver qualquer atraso no processo de
                  tratamento da consulta.
                </p>
              </li>
            </ol>
          </article>

          <article>
            <h2>SEÇÃO 11 ~ DISPOSIÇÕES FINAIS</h2>

            <ol>
              <li>
                <p>
                  <mark>11.1.</mark> Até o momento, o Sulkey não utiliza
                  ferramentas específicas para documentar e rastrear o
                  consentimento dos USUÁRIOS.
                </p>
              </li>
              <li>
                <p>
                  <mark>11.2.</mark>
                  Para a solução de controvérsias decorrentes do presente
                  instrumento será aplicado integralmente o Direito brasileiro.
                </p>
              </li>
              <li>
                <p>
                  <mark>11.3.</mark>
                  Os eventuais litígios deverão ser apresentados no foro da
                  comarca em que se encontra a sede da empresa.
                </p>
              </li>
            </ol>
          </article>
        </section>
      </article>
    </section>
  );
}
