import { useState, useEffect } from "react";
import { sendRequest } from "../../../../util/util";
import ComponentLoading from "../../../ComponentLoading";

export default function Pool(props) {
  const [answerData, setAnswerData] = useState(() => ({}));
  const [poolIsLoading, setPoolIsLoading] = useState(() => false);
  const [selected, setSelected] = useState(() => {
    return {
      1: false,
      2: false,
      3: false,
      4: false,
    };
  });

  const [showNotAllowed, setShowNotAllowed] = useState(() => {
    return false;
  });

  let userData = localStorage.getItem("userData");
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    async function getPoolData() {
      userData = JSON.parse(userData);
      try {
        setPoolIsLoading(() => true);
        const requestData = {
          method: "POST",
          url: "quizz-answers/single",
          body: {
            userId: userData.id,
            quizzId: props.pool.id,
          },
          isAdminServer: true,
        };
        const response = await sendRequest(requestData);

        if (!response.success) {
          return;
        }

        setSelected((prevSelected) => {
          return {
            ...prevSelected,
            [response.data.option]: true,
          };
        });

        setAnswerData(() => response.data);
      } catch (error) {
        window.alert(`Houve um erro no servidor. ${error}`);
      } finally {
        setPoolIsLoading(() => false);
      }
    }

    if (userData && userToken) {
      getPoolData();
    }
  }, []);

  const [showThxMessage, setShowThxMessage] = useState(() => false);

  if (!props.pool) {
    return;
  }

  const answers = JSON.parse(props.pool.answers);

  const poolContainers = answers.map((answer, index) => {
    const option = index + 1;

    const answerExists = Object.keys(answerData).length >= 1;

    const isSelected = selected[option];

    return (
      <PoolContainer
        key={index}
        option={option}
        answer={answer}
        selected={isSelected}
        handleSelectAnswer={() => {
          handleSelectAnswer(
            setShowThxMessage,
            props.pool.id,
            option,
            answerExists,
            setAnswerData,
            setSelected,
            userData,
            setShowNotAllowed
          );
        }}
      />
    );
  });

  return (
    <div>
      <ShowSelectedConfirm showThxMessage={showThxMessage} />
      <ShowNotAllowedContainer showNotAllowed={showNotAllowed} />

      {poolIsLoading ? (
        <div className="relative">
          <ComponentLoading />
        </div>
      ) : (
        <form>
          <div className="rounded-md">
            <div className="p-4 bg-communityprimary rounded-t-md">
              <h2 className="text-white font-bold text-lg ">
                {props.pool.question}
              </h2>
            </div>

            <div className="p-4 pt-8 pb-8 bg-white border border-communityprimary rounded-b-md flex flex-col gap-5">
              {poolContainers}
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

function PoolContainer(props) {
  return (
    <div
      onClick={props.handleSelectAnswer}
      className={`rounded-3xl border-communityprimary pool-option ${
        props.selected && "active"
      } border cursor-pointer p-3 flex items-center gap-4`}
    >
      <mark className="rounded-full font-bold text-base text-communityprimary w-8 h-8 flex items-center justify-center border border-communityprimary">
        {props.option}
      </mark>

      <p className="font-medium text-base text-communityprimary">
        {props.answer}
      </p>
    </div>
  );
}

function ShowNotAllowedContainer(props) {
  return (
    <div
      className={`fixed ${
        props.showNotAllowed ? "top-0" : "-top-40"
      } transition-all duration-500 z-50 w-full max-w-[40rem] left-1/2 transform -translate-x-1/2 p-6 bg-communityprimary`}
    >
      <h3 className="text-white font-medium text-center text-lg">
        Faça Login para poder votar!
      </h3>
    </div>
  );
}

function ShowSelectedConfirm(props) {
  return (
    <div
      className={`fixed ${
        props.showThxMessage ? "top-0" : "-top-40"
      } transition-all duration-500 z-50 w-full max-w-[40rem] left-1/2 transform -translate-x-1/2 p-6 bg-communityprimary`}
    >
      <h3 className="text-white font-medium text-center text-lg">
        Obrigado pelo feedback!
      </h3>
    </div>
  );
}

async function handleSelectAnswer(
  setShowThxMessage,
  quizzId,
  option,
  answerExists,
  setAnswerData,
  setSelected,
  userData,
  setShowNotAllowed
) {
  if (!userData) {
    setShowNotAllowed(() => true);

    setTimeout(() => {
      setShowNotAllowed(() => false);
    }, 3000);
    return;
  }

  userData = JSON.parse(userData);

  setSelected((prevSelected) => {
    return {
      1: false,
      2: false,
      3: false,
      4: false,
      [option]: true,
    };
  });

  try {
    const requestData = {
      method: answerExists ? "PUT" : "POST",
      url: "quizz-answers",
      body: {
        userId: userData.id,
        quizzId,
        option,
      },
      isAdminServer: true,
    };
    const response = await sendRequest(requestData);

    if (!response.success) {
      throw new Error(response.message);
    }

    setAnswerData(() => response.data);

    setShowThxMessage(() => true);
    setTimeout(() => {
      setShowThxMessage(() => false);
    }, 2000);
  } catch (error) {
    window.alert(`Houve um erro no servidor. ${error}`);
  }
}
