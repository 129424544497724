import { useParams, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { sendRequest } from "../../../../util/util";
import Pool from "./Pool";
import ComponentLoading from "../../../ComponentLoading";

export default function Post() {
  const id = useParams().id;

  const [postData, setPostData] = useState(() => ({}));
  const [postIsLoading, setPostIsLoading] = useState(() => true);

  useEffect(() => {
    async function handleGetPostsRequest() {
      setPostIsLoading(() => true);

      const requestData = {
        method: "GET",
        url: `public/posts/${id}`,
        isAdminServer: true,
      };

      try {
        const response = await sendRequest(requestData);

        if (!response.success) {
          return;
        }

        setPostData(() => response.data);
      } catch (error) {
        window.alert(`Houve um erro no servidor ${error}`);
      } finally {
        setPostIsLoading(() => false);
      }
    }

    handleGetPostsRequest();
  }, []);

  if (!validateId(id)) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <section>
      {postIsLoading ? (
        <div className="relative p-10 pt-48 pb-48">
          <ComponentLoading customBg="bg-none" />
        </div>
      ) : (
        <div>
          {Object.keys(postData).length >= 1 ? (
            <article className="m-auto flex flex-col gap-4 max-w-[43rem] pb-14 sm:pl-3 sm:pr-3 sm:pt-14">
              <picture>
                <img
                  className="w-full max-h-[25rem] object-cover block sm:h-[24rem] sm:rounded-md"
                  src={postData.thumbnail}
                />
              </picture>

              <article className="flex flex-col gap-4 p-3 sm:p-0">
                <h1 className="text-2xl text-communityprimary font-bold max-w-[50ch]">
                  {postData.title}
                </h1>

                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{ __html: postData.content }}
                />
              </article>

              {postData.quizz && (
                <div className="mt-8 pl-2 pr-2 sm:p-0">
                  <Pool pool={postData.quizz} />
                </div>
              )}
            </article>
          ) : (
            <div className="p-3 pt-32 pb-32">
              <h1 className="text-red-500 font-bold text-2xl text-center">
                Post não encontrado
              </h1>
            </div>
          )}
        </div>
      )}
    </section>
  );
}

function validateId(postId) {
  const idValidated = parseInt(postId);
  if (Number.isNaN(idValidated) || idValidated <= 0) {
    return false;
  }

  return true;
}
